export * from './model';

export * from './book-claims';
export * from './cat-client';
export * from './client-alias';
export * from './client-lin-cre';
export * from './client';
export * from './cotizacion';
export * from './ctabco';
export * from './departamento';
export * from './distrito';
export * from './dtl-cotizacion';
export * from './dtl-pedido';
export * from './email-send';
export * from './list-documents';
export * from './local';
export * from './login';
export * from './marca-prd';
export * from './pais';
export * from './pedido';
export * from './postal';
export * from './product-byid';
export * from './product-desc';
export * from './product-find';
export * from './product-logs';
export * from './provincia';
export * from './result';
export * from './tipodespacho';
export * from './token';
export * from './ubigeo';
export * from './user-logs';
export * from './user';
export * from './vendedor';
export * from './zona';
export * from './ConfigFile';
