import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService, NavigationService, ToolsService } from 'src/app/core/services';
import { FunctionsService } from 'src/app/core/helpers';
import { ProductLogs, Result } from '../../models';

@Component({
  selector: 'app-select-marks',
  templateUrl: './select-marks.component.html',
  styleUrls: ['./select-marks.component.scss']
})
export class SelectMarksComponent implements OnInit {

  public listMarks: any = [];
  public listFamlies: any = [];
  @Input('var_codUsu') var_codUsu: string;
  @Input('var_iduser') var_iduser: string;
  @Output() codeSelect: EventEmitter<string>;

  public codMar: string;
  public codFam: string;
  public subscriptions: Subscription = new Subscription();
  public dataMsjError: any = {};
  public loadingspinner: boolean;
  @ViewChild('modalError') modalError: TemplateRef<any>;
  typeCompany: string;

  constructor(
    private config: ConfigurationService,
    public toolsService: ToolsService,
    private modalService: NgbModal,
    private navigationSrv: NavigationService,
    public mf: FunctionsService
  ) {
    this.codeSelect = new EventEmitter();
    this.typeCompany = this.config.result.typeCompany;
  }

  ngOnInit(): void {
    switch (this.typeCompany) {
      case 'standar':
        this.subscriptions.add(this.getDataMarks());
        break;

      case 'games':
        this.subscriptions.add(this.getDataFamilies());
        break;

      default:
        this.subscriptions.add(this.getDataMarks());
        break;
    }
  }

  private getDataFamilies(): Subscription {
    this.loadingspinner = true;
    return this.navigationSrv.getTblClasifPrd().subscribe((res: ProductLogs) => {
      if (res.success) {
        setTimeout(() => {
          this.loadingspinner = false;
        }, 1000);
        this.listFamlies = res?.data?.familia;
      } else {
        this.setDataError(res, this.modalError);
      }
    }, (err) => {
      console.log(err);
      this.setDataError(err, this.modalError);
    })
  }

  private getDataMarks(): Subscription {
    this.loadingspinner = true;
    const data = this.mf.getDataMarcaPrdByFilter(this.var_codUsu, this.var_iduser);
    return this.toolsService.getAllMarks(data).subscribe((res: Result) => {
      if (res.success) {
        setTimeout(() => {
          this.loadingspinner = false;
        }, 1000);
        this.listMarks = res.data;
      } else {
        this.setDataError(res, this.modalError);
      }
    }, (err) => {
      console.log(err);
      this.setDataError(err, this.modalError);
    })
  }

  public onClickSelectMark(codMar: string = null) {
    this.codeSelect.emit(codMar);
  }

  public onClickSelectFamily(codFam: string = null) {
    this.codeSelect.emit(codFam);
  }

  private setDataError(datamsj: any, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    let configModal: any = { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' };
    this.modalService.open(nameModal, configModal).result.then((result) => {
      this.loadingspinner = false;
    }, (reason) => {
      this.loadingspinner = false;
    });
  }

}
