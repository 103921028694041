import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/services';
import { sectionFotter, socialMedia } from 'src/app/shared/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  anio: number;
  flagviewimgfe: boolean;
  typeCompany: string;
  nameCompany: string;
  urlWebFE: string;
  flagViewCtrl: boolean;
  isLoadUser: boolean;
  linkSocialMedia: socialMedia[];
  sectionFotter: sectionFotter;

  constructor(
    private configsvr: ConfigurationService
  ) {
    this.typeCompany = this.configsvr.result.typeCompany;
    this.nameCompany = this.configsvr.result.namebusiness;
    this.linkSocialMedia = this.configsvr.result.linkSocialMedia;
    this.urlWebFE = this.configsvr.result.urlWebFE;
    this.sectionFotter = this.configsvr.result.sectionFotter;
  }

  ngOnInit(): void {
    this.anio = new Date().getFullYear();
    this.flagviewimgfe = this.configsvr.result.viewImageFooterFE;
  }

  public includesArray(array: any[], text: string): boolean{
    return array.includes(text);
  }

}
