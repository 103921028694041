import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/services';

@Component({
  selector: 'app-znsearch',
  templateUrl: './znsearch.component.html',
  styleUrls: ['./znsearch.component.scss']
})
export class ZnsearchComponent implements OnInit {
  
  typeCompany: string;
  textBaner: string;
  @Input('flagviewzn') pnltextsearch:boolean;

  constructor(private config: ConfigurationService,) { 
    this.typeCompany = this.config.result.typeCompany;
  }

  ngOnInit(): void {
    switch (this.typeCompany) {
      case 'standar':
        this.textBaner = 'Busca tus productos por MARCA, CODIGO o NOMBRE';
        break;

      case 'games':
        this.textBaner = 'Busca tus productos por CATEGORÍA, CODIGO o NOMBRE';
        break;

      default:
        this.textBaner = 'Busca tus productos por MARCA, CODIGO o NOMBRE';
        break;
    }
  }

}
