import { Injectable } from '@angular/core';
import { Cotizacion, Result } from 'src/app/shared/models';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {

  public filterFormCot: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public listQuotes: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private apiService: ApiService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined ) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Cotizacion/` : this.config.result.apiUrl + `api/Cotizacion/`;
    return apiUrl;
  }

    /********************************************* */
    public setFilterFormCot(data: any) {
      this.filterFormCot.next(data);
    }
  
    public getFilterFormCot() {
      return this.filterFormCot.asObservable();
    }
  
    public seListQuotes(data: any) {
      this.listQuotes.next(data);
    }
  
    public getListQuotes() {
      return this.listQuotes.asObservable();
    }
    /********************************************* */

  public getCotizacionByFilter(cotizacion: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetCotizacionByFilter`;
    return this.http.post(endpoint, cotizacion, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getCotizacionByID(cotizacion: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetCotizacionById/${cotizacion.cdocu}/${cotizacion.nro}`;
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public postPostCotizacion(cotizacion: Cotizacion): Observable<Result> {
    const endpoint = `${this.baseUrl}PostCotizacion`;
    return this.http.post(endpoint, cotizacion, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public UpdateCotizacion(cotizacion: Cotizacion): Observable<Result> {
    const endpoint = `${this.baseUrl}UpdateCotizacion`;
    return this.http.post(endpoint, cotizacion, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public deleteCotizacion(cotizacion: any): Observable<Result> {
    const endpoint = `${this.baseUrl}DeleteCotizacion`;
    return this.http.post(endpoint, cotizacion, this.apiService.requestOptions).pipe(map(Result.cast));
  }

}
