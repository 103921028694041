import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-search',
  templateUrl: './alert-search.component.html',
  styleUrls: ['./alert-search.component.scss']
})
export class AlertSearchComponent implements OnInit {

  @Input('flagviewAlert') resultSearchProd:boolean;
  @Input('AlertText') textAlertResultSearch:string;

  constructor() { }

  ngOnInit(): void {
  }

}
