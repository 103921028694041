import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class ProductQuery extends Model {
  public message: string;
  public data: ProductDesc[];

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductQuery {
    return new ProductQuery(data);
  }

  public static casts(data: object[]): ProductQuery[] {
    return map(data, c => new ProductQuery(c));
  }
}

export class ProductDesc extends Model {
  public idCodi: string;
  public codigo: string;
  public descr: string;
  public marca: string;
  public codMarca: string;
  public clase: string;
  public umed: string;
  public stock: number;
  public mone: string;
  public aigv: string;
  public vvns: number;
  public vvus: number;
  public pvns: number;
  public pvus: number;
  public msto: string;
  public peso: number;
  public volumen: number;
  public codAlm: string;
  public codPaiPro: string;
  public codPaiOri: string;
  public descrLarga: string;
  public obse: string;
  public imagen: string;
  public codAlterno: string;
  public codParte: string;
  public docFichaTec: string;
  public docAdjunto1: string;
  public docAdjunto2: string;
  public txtAdd1: string;
  public txtAdd2: string;
  public txtAdd3: string;
  public umedMinCom: string;
  public cantMinCom: number;
  public idProducto: number;
  public flagPres: number;
  public dsctoDefault: number;
  public dsctoMaxVen: number;
  public dsctoMaxGer: number;
  public nomAlm: string;
  public nomFam: string;
  public nomSbf: string;
  public nomGrp: string;
  public presen: string;

  // Add Columna
  public Item: number;
  public CantPed: number;
  public Preu: number;
  public Tota: number;
  public Totn: number;
  public UndConvPres: number;
  public UmedDoc: string;

  constructor(data?: object) {
    super(data);
    this.UmedDoc = this.UmedDoc || this.umed;
  }

  public static cast(data: object): ProductDesc {
    return new ProductDesc(data);
  }

  public static casts(data: object[]): ProductDesc[] {
    return map(data, c => new ProductDesc(c));
  }
}
