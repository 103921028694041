// import { IConfigFile } from 'src/app/shared/interfaces';
import { ConfigFile } from 'src/app/shared/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

export type ConfigKey = keyof ConfigFile;

export const TEST_CONFIG: ConfigFile = {
  envName: 'test',
  codCia: '51',
  apiUrl: '',
  imageUrl: '',

  /***==> DATA DE LA EMPRESA***************** */
  namebusiness: '',
  fromAddress: '',
  urlGoogleMaps: '',
  linkSocialMedia: [
    { nameRed: '', icon: '', url: '' }
  ],
  sectionFotter: {
    howtoBuy: [''],
    myCompany: [''],
    contactUs: ['']
  },
  /***==> DATA DE CONFIGURACION SISTEMA***************** */
  keyRecaptcha: '6LdfxrMZAAAAAJIuDPrUyE9OpKslBtGLf-vI6-qx',
  typeCompany: 'standar',
  viewImageGrid: false,
  viewImageFooterFE: false,
  urlWebFE: '',
  viewTypeSearchProduct: 2, 
  selectTypeSearchCombo: 'cod', 
  nroDecimal_price: 3,
  nroDecimal_totals: 2,
  flagViewStockOtherAlms: true, 
  flagViewDiscountAmountTable: false,
  viewTypeResultSearch: 1,
  /***==> DATA DE CONFIGURACION TEMA DEL SISTEMA***************** */
  theme: [
    { id: '', valor: '' }
  ]
};

const WINDOW_CONFIG = window['SYS_ECOMMERCE_CONFIG'];

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public result: ConfigFile = _.clone(WINDOW_CONFIG || TEST_CONFIG);
  constructor(private http: HttpClient) { }
}
