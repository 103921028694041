import { Injectable } from '@angular/core';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Result } from 'src/app/shared/models';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private apiService: ApiService
  ) { }

  public get baseUrlTools(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/tools/` : this.config.result.apiUrl + `api/tools/`;
    return apiUrl;
  }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Pedido/` : this.config.result.apiUrl + `api/Pedido/`;
    return apiUrl;
  }

  public get baseUrlPrd(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Producto/` : this.config.result.apiUrl + `api/Producto/`;
    return apiUrl;
  }

  public getOrdersByPDF(dataDoc: any): Observable<Result> {
    const endpoint = `${this.baseUrlTools}GetDocumentToPdf`;
    return this.http.post(endpoint, dataDoc, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getTblVentas(): Observable<Result> {
    const endpoint = `${this.baseUrl}GetTblVentas`;
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getTrackingDocument(document: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetTrackingPedidoById/${document.cdocu}/${document.nro}`;
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public sendMailDocument(dataDoc: any): Observable<Result> {
    const endpoint = `${this.baseUrlTools}SendMail`;
    return this.http.post(endpoint, dataDoc, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public sendClaimBook(data: any): Observable<Result> {
    const endpoint = `${this.baseUrlTools}PostLibroReclamo`;
    return this.http.post(endpoint, data, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getAllMarks(data: any): Observable<Result> {
    const endpoint = `${this.baseUrlPrd}GetMarcaPrdByFilter`;
    return this.http.post(endpoint, data, this.apiService.requestOptions).pipe(map(Result.cast));
  }

}
