import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Ctabco extends Model {
  public nroCta: string;
  public nomBco: string;
  public moneda: string;
  public nroCtaCCI: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Ctabco {
    return new Ctabco(data);
  }

  public static casts(data: object[]): Ctabco[] {
    return map(data, c => new Ctabco(c));
  }
}
