import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';
import {ListDocuments} from './list-documents';

export class ClientLinCre extends Model {
  public FlagLinCre: number;
  public FlagDocven: number;
  public MonedaLinCre: string;
  public MontoLinCre: number;
  public ConsumoLinCre: number;
  public SaldoLinCre: number;
  public Documento: ListDocuments;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ClientLinCre {
    return new ClientLinCre(data);
  }

  public static casts(data: object[]): ClientLinCre[] {
    return map(data, c => new ClientLinCre(c));
  }
}
