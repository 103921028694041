import {isString, map, pickBy} from 'lodash';

import {Model} from './model';

export class UserLogSingle extends Model {
    idapo: number;
    nomapo: string;
    userapo: string;
    passapo: string;
    token: string;

    constructor(data?: object) {
        super(data);
    }
}

