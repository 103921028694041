import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-message-error',
  templateUrl: './modal-message-error.component.html',
  styleUrls: ['./modal-message-error.component.scss']
})
export class ModalMessageErrorComponent implements OnInit {

  @Input() dataMsjError: {
    nameErr: string,
    detailErr: string,
    codeErr: any
  }

  constructor(
    private router: Router,
    public configModal: NgbModalConfig,
    public activeModal: NgbActiveModal
  ) {
    configModal.backdrop = 'static';
    configModal.keyboard = false;
  }

  ngOnInit(): void {
  }

  closeModalErr(){
    if(this.dataMsjError.codeErr === 401){
      this.router.navigate(['/sessions/signin']);
    }
    this.activeModal.close('Close click')
  }

}
