import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class DtlPedido extends Model {

  public Cdocu: string;
  public Ndocu: string;
  public Item: number;
  public Codi: string;
  public Codf: string;
  public Marc: string;
  public Umed: string;
  public Descr: string;
  public Aigv: string;
  public CantPed: number;
  public CantApr: number;
  public CantDes: number;
  public Preu: number;
  public Dsct: number;
  public Dsct2: number;
  public Dsct3: number;
  public Tota: number;
  public Totn: number;
  public Cost: number;
  public CodAlm: string;
  public Msto: string;
  public UmedDoc: string;         // ucom
  public UndConvPres: number;    // ucon
  public MedCorte: number;
  public PrecioSug: number;
  public ObsPrecioSug: string;
  public PreuOri: number;
  public AnulaDetalle: string;

  // public flagRollo:number;
  // public flagtramo:number;
  // public medCorte:number;
  public NroPieza:number;

  // Campos adicionales TMP solo para edicion y consulta
  public DsctoDefault: number;
  public DsctoVendedor: number;
  public DsctoGerencia: number;
  public Peso_Prd: number;
  public Vvns_LP: number;
  public Pvns_LP: number;
  public Vvus_LP: number;
  public Pvus_LP: number;
  public PresItm_Prd: number;
  public MargenMin: number;
  public Stock: number;
  public imagen: string;

  constructor(data?: object) {
    super(data);
    this.Cdocu = this.Cdocu || '32';
    this.Ndocu = this.Ndocu || '000-00000000';
    this.Item = this.Item || 0;
    this.Codi = this.Codi || '';
    this.Codf = this.Codf || '';
    this.Marc = this.Marc || '';
    this.Umed = this.Umed || '';
    this.Descr = this.Descr || '';
    this.Aigv = this.Aigv || '';
    this.CantPed = this.CantPed || 0;
    this.CantApr = this.CantApr || 0;
    this.CantDes = this.CantDes || 0;
    this.Preu = this.Preu || (this.Totn / this.CantPed)  ;
    this.Dsct = this.Dsct || 0;
    this.Dsct2 = this.Dsct2 || 0;
    this.Dsct3 = this.Dsct3 || 0;
    this.Tota = this.Tota || 0;
    this.Totn = this.Totn || 0;
    this.Cost = this.Cost || 0;
    this.CodAlm = this.CodAlm || '01';
    this.Msto = this.Msto || '';
    this.UmedDoc = this.UmedDoc || '';         // ucom
    this.UndConvPres = this.UndConvPres || 1;    // ucon
    this.MedCorte = this.MedCorte || 0;
    this.PrecioSug = this.PrecioSug || 0;
    this.ObsPrecioSug = this.ObsPrecioSug || '';
    this.PreuOri = this.PreuOri || (this.Totn / this.CantPed);
    this.AnulaDetalle = this.AnulaDetalle || 'N';
    this.NroPieza =  this.NroPieza || 0;
  }

  public static cast(data: object): DtlPedido {
    return new DtlPedido(data);
  }

  public static casts(data: object[]): DtlPedido[] {
    return map(data, c => new DtlPedido(c));
  }
}
