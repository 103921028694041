import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './shared/components/layouts/admin/admin.component';
import { RedirectGuard } from './core/guard/redirect.guard';
import { AuthGuard } from './core/guard/auth.guard';


const adminRoutes: Routes = [
  {
    path: 'sessions',
    loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
    canActivate: [RedirectGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  }
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminComponent,
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: 'sessions/signin',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
