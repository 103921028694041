import {  each, isNumber, isString, map, reduce  } from 'lodash';
import {Model} from './model';

export class ProductLogs extends Model {
  public message: string;
  public data: ProductData;
  public error: string;
  public success: boolean;
  public codFam: string;

  constructor(data?: object) {
    super(data);
    this.codFam = '000';
  }

  public static cast(data: object): ProductLogs {
    return new ProductLogs(data);
  }

  public static casts(data: object[]): ProductLogs[] {
    return map(data, c => new ProductLogs(c));
  }
}

export class ProductData extends Model {
  public id: number;
  public familia: ProductFamily;
  public subfamilia: ProductSubfamily;
  public grupo: number;
  public marca: ProductMarca;
  public tipoQuery: number;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductLogs {
    return new ProductLogs(data);
  }

  public static casts(data: object[]): ProductLogs[] {
    return map(data, c => new ProductLogs(c));
  }
}

export class ProductFamily extends Model {
  public codFam: string;
  public nomFam: string;
  public subfamilia: ProductSubfamily[];
  public isShow: boolean;

  constructor(data?: object) {
    super(data);
    this.isShow = false;
  }

  public static cast(data: object): ProductFamily {
    return new ProductFamily(data);
  }

  public static casts(data: object[]): ProductFamily[] {
    return map(data, c => new ProductFamily(c));
  }

}

export class ProductSubfamily extends Model {
  public codSub: string;
  public nomSub: string;
  public codFam: string;

  constructor(data?: object) {
    super(data);
    if (isString(this.codSub)) {
      this.codFam = this.codSub.substring(0, 2);
    } else {
      this.codFam = '000';
    }
  }

  public get codFamDisplay(): string {
    return  this.codSub;
  }

  public setcodFamDisplay() {
    this.codFam = isString(this.codSub) ? this.codSub.substring(0, 2) : '';
  }

  public static cast(data: object): ProductSubfamily {
    return new ProductSubfamily(data);
  }

  public static casts(data: object[]): ProductSubfamily[] {
    return map(data, c => new ProductSubfamily(c));
  }
}

export class ProductMarca extends Model {
  public codMar: string;
  public nomMar: string;
  public abrMar: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductMarca {
    return new ProductMarca(data);
  }

  public static casts(data: object[]): ProductMarca[] {
    return map(data, c => new ProductMarca(c));
  }
}
