import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsService } from 'src/app/core/helpers';
import { ConfigurationService, ImageService } from 'src/app/core/services';
import { ModalFullImageComponent } from '../modal-full-image/modal-full-image.component';

@Component({
  selector: 'app-modal-img-product',
  templateUrl: './modal-img-product.component.html',
  styleUrls: ['./modal-img-product.component.scss']
})
export class ModalImgProductComponent implements OnInit {

  @Input('nameImgPrd') imgprd: string;
  @Input('stock') stock: number;
  @Input('dataPrd') dataPrd: any;
  @Input('priceIncluIgv') priceIncluIgv: number;

  dataImgprd: any = {};
  nroDecimal: number;
  name_img: string = '';
  img_first: string = '';

  constructor(
    public configsvr: ConfigurationService,
    public imageService: ImageService,
    private modalService: NgbModal,
    public mf: FunctionsService
  ) { }

  ngOnInit(): void {
    this.nroDecimal = this.configsvr.result.nroDecimal_totals;
    this.img_first = `${this.dataPrd.codigo}_1.jpg`;
  }

  showVisorImg(contentPRD) {
    this.dataImgprd = {
      descr: this.dataPrd.descr,
      idCodi: this.dataPrd.idCodi,
      marca: this.dataPrd.marca,
      mone: this.dataPrd.mone,
      pvns: this.dataPrd.pvns,
      pvus: this.dataPrd.pvus,
      vvns: this.dataPrd.vvns,
      vvus: this.dataPrd.vvus,
      nomFam: this.dataPrd.nomFam,
      nomSbf: this.dataPrd.nomSbf,
      imagen: this.dataPrd.imagen,
      stock: this.dataPrd.stock,
      codigo: this.dataPrd.codigo
    };
    this.modalService.open(contentPRD, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
  }

  fullImagePrd(img: string) {
    let config: any = { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' };
    const modalRef: NgbModalRef = this.modalService.open(ModalFullImageComponent, config);
    modalRef.componentInstance.prd_img = img;
    modalRef.componentInstance.prd_name = this.dataImgprd.descr;
    modalRef.componentInstance.prd_codigo = this.dataImgprd.codigo;
  }

}
