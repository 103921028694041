import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Postal extends Model {
  public CodPos: string;
  public NomPos: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Postal {
    return new Postal(data);
  }

  public static casts(data: object[]): Postal[] {
    return map(data, c => new Postal(c));
  }
}
