import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ConfigurationService, SearchService } from 'src/app/core/services';
import { FunctionsService } from 'src/app/core/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductByid } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-select-product',
  templateUrl: './modal-select-product.component.html',
  styleUrls: ['./modal-select-product.component.scss']
})
export class ModalSelectProductComponent implements OnInit {

  @Input('idcodi') idcodi: string;
  @Input('codUsu') codUsu: string;
  @Input('codCdv') codCdv: string;
  @Input('moneda') moneda: string;
  @Input('tcambio') tcambio: number;
  @Input('namedocu') namedocu: number;
  @Input('priceIncluIgv') priceIncluIgv: number;
  @Input('datoprd_pvns') datoprd_pvns: number;
  @Input('datoprd_pvus') datoprd_pvus: number;
  @Input('datoprd_vvns') datoprd_vvns: number;
  @Input('datoprd_vvus') datoprd_vvus: number;
  @Output() dataPrdSelect: EventEmitter<any>;

  flagviewTipOp: boolean;
  tmp_prd: any = {};
  listUmed: any = [];
  nroDecimalpreci: number;
  nroDecimal: number;
  warnCANT: boolean
  localprice: number;
  nameprd: string;
  nametaskPrice: string;  
  viewTypeResultSearch: number;
  loadingspinner: boolean;
  @ViewChild('modalError') modalError: TemplateRef<any>;
  @ViewChild('contentSelectPrd') modalSelectPrd: TemplateRef<any>;
  dataMsjError: any = {};
  private subscriptions: Subscription = new Subscription();

  constructor(
    public configsvr: ConfigurationService,
    public searchPrd: SearchService,
    private modalService: NgbModal,
    public mf: FunctionsService
  ) {
    this.dataPrdSelect = new EventEmitter();
  }

  ngOnInit(): void {
    this.nroDecimalpreci = this.configsvr.result.nroDecimal_price;
    this.nroDecimal = this.configsvr.result.nroDecimal_totals;
    this.viewTypeResultSearch = this.configsvr.result.viewTypeResultSearch;
  }

  public get_priceLista() {
    let importe: number = (this.priceIncluIgv == 1) ? ((this.moneda == 'S') ? this.datoprd_pvns : this.datoprd_pvus) : ((this.moneda == 'S') ? this.datoprd_vvns : this.datoprd_vvus);
    let roundImporte: number = this.mf.roundDecimals(importe, this.nroDecimal);
    return this.mf.getConvertDecimal(roundImporte);
  }

  public openModalSelect() {
    this.loadingspinner = true;
    const data = this.mf.getdataProductobyFilterByID(this.idcodi, this.codUsu, this.codCdv);
    this.searchPrd.postProductoById(data).subscribe((res: ProductByid) => {
      if (res.success) {
        setTimeout(() => {
          this.loadingspinner = false;
        }, 1000);

        this.showDataProductModal(res.data);
        this.getNameTaskPrice(this.priceIncluIgv);
        this.viewModalInputs();
        this.modalService.open(this.modalSelectPrd, { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
      } else {
        this.setDataError(res, this.modalError);
      }

    }, err => {
      this.loadingspinner = false;
    })
  }

  private setDataError(datamsj: ProductByid, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    this.modalService.open(nameModal, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' }).result.then((result) => {
      this.loadingspinner = false;
    }, (reason) => {
      this.loadingspinner = false;
    });
  }

  private showDataProductModal(product: any) {
    this.setDataObjetPrdModal(product);
    if (this.tmp_prd.propresen === 0) {
      if (this.tmp_prd.flagtramo === 1 || this.tmp_prd.flagRollo === 1) {
        this.tmp_prd.UmedSelect = product.umed;
        this.localprice = this.mf.getConvertPriceByMone(product.mone, this.moneda, product.pvns, product.pvus, this.tcambio, this.priceIncluIgv, product.vvns, product.vvus);
        this.nameprd = product.descr;
      } else {
        this.listUmed = [{ umed: product.umed }];
        this.setImportesModalToMone(this.tmp_prd.mone, product.pvns, product.pvus, product.pvns, product.pvus, this.moneda, product.umed, 1, this.priceIncluIgv, product.vvns, product.vvus);
      }
    } else {
      this.tmp_prd.UmedSelect = product.umed;
      this.subscriptions.add(this.listPresentPrd(product));
    }
  }

  private setDataObjetPrdModal(product: any) {
    const valCost = this.mf.getValCostoProduct(this.moneda, product.mone, product.pans, product.paus, this.tcambio, this.nroDecimalpreci);
    this.tmp_prd = {};
    this.tmp_prd = {
      cantSelect: 1,
      nropieza: 1,
      medidacorte: 0,
      flagtramo: product.flagTramo,
      flagRollo: product.flagRollo,
      ProducSelect: product.descr,
      CodiSelect: product.idCodi,
      propresen: product.flagPres,
      mone: product.mone,
      codf: product.codigo,
      marc: product.marca,
      aigv: product.aigv,
      msto: product.msto,
      umed: product.umed,
      dsct: product.dsctoDefault,
      cost: valCost,
      codAlm: product.codAlm,
      priceSelect: 0,
      totn: 0
    }

    let priceByMone: number = this.mf.getConvertPriceByMone(product.mone, this.moneda, this.datoprd_pvns, this.datoprd_pvus, this.tcambio, this.priceIncluIgv, this.datoprd_vvns, this.datoprd_vvus);
    this.tmp_prd.preuori = this.mf.roundDecimals(priceByMone, this.nroDecimalpreci);

    // this.tmp_prd.preuori = this.mf.roundDecimals((this.mf.getConvertPriceByMone(product.mone, this.moneda, product.pvns, product.pvus, this.tcambio, this.priceIncluIgv, product.vvns, product.vvus)), this.nroDecimalpreci);
  }

  private setImportesModalToMone(mone_prd: string, pvns: number, pvus: number, pvns_orig: number, pvus_orig: number, mone_default: string, umeds: string, undconv: number, priceIncluIgv: number, vvns: number, vvus: number) {
    let preu = this.mf.getConvertPriceByMone(mone_prd, mone_default, pvns, pvus, this.tcambio, priceIncluIgv, vvns, vvus);
    this.tmp_prd.UmedSelect = umeds;
    this.tmp_prd.undConvPres = undconv;
    this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(preu, this.nroDecimalpreci));
    this.tmp_prd.priceOLD = this.mf.getConvertPriceByMone(mone_prd, mone_default, pvns_orig, pvus_orig, this.tcambio, priceIncluIgv, vvns, vvus);
    this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, preu, this.nroDecimal));
  }

  private listPresentPrd(product: any) {
    const data = this.mf.getDataFilterPresen(product.idCodi, this.codUsu, this.codCdv);
    this.searchPrd.getPresentProductByFilter(data).subscribe((res: any) => {
      if (res.success) {
        this.listUmed = res.data;
        this.setImportesModalToMone(this.tmp_prd.mone, res.data[0].pvns, res.data[0].pvus, product.pvns, product.pvus, this.moneda, res.data[0].umed, res.data[0].undConvPres, this.priceIncluIgv, res.data[0].pvns, res.data[0].pvus);
      } else {
        this.setDataError(res, this.modalError)
      }
    })
  }

  private getNameTaskPrice(priceIncluIgv: number) {
    if (priceIncluIgv === 1) {
      this.nametaskPrice = 'Precio Unitario';
    } else {
      this.nametaskPrice = 'Valor Unitario';
    }
  }

  private viewModalInputs() {
    const flagTramo: number = this.tmp_prd.flagtramo;
    const flagRollo: number = this.tmp_prd.flagRollo;
    if (flagTramo === 1 || flagRollo === 1) {
      this.flagviewTipOp = false;
    } else {
      this.flagviewTipOp = true;
    }
  }

  public changeComboListPresen(codepro: string, cant: number) {
    if (this.tmp_prd.propresen === 1) {
      this.tmp_prd.priceSelect = 0;
      const umedS = this.tmp_prd.UmedSelect;
      Object.values(this.listUmed).forEach((pre: any) => {
        if (pre.idCodi === codepro) {
          if (pre.umed === umedS) {
            let price = this.mf.getConvertPriceByMone(this.tmp_prd.mone, this.moneda, pre.pvns, pre.pvus, this.tcambio, this.priceIncluIgv, pre.pvns, pre.pvus);
            if (price !== 0) {
              this.tmp_prd.undConvPres = pre.undConvPres;
              this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(price, this.nroDecimalpreci));
              this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(cant, price, this.nroDecimal));
            } else {
              this.tmp_prd.undConvPres = pre.undConvPres;
              price = this.tmp_prd.priceOLD * pre.undConvPres;
              this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(price, this.nroDecimalpreci));
              this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(cant, price, this.nroDecimal));
            }
          }
        }
      })
    }
  }

  public setDecrease_cant(cant: number, price: number, flagTrama: number, flagRollo: number) {
    if (cant > 1) {
      if (flagTrama === 1 || flagRollo === 1) {
        this.tmp_prd.nropieza = this.tmp_prd.nropieza - 1
        this.tmp_prd.cantSelect = this.tmp_prd.nropieza * this.tmp_prd.medidacorte;
      } else {
        this.tmp_prd.cantSelect = this.tmp_prd.cantSelect - 1
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, price, this.nroDecimal));
      }
    } else {
      this.warnCANT = true;
    }
  }

  public setIncrease_cant(cant: number, price: number, flagTrama: number, flagRollo: number) {
    if (cant >= 0) {
      this.warnCANT = false;
      if (flagTrama === 1 || flagRollo === 1) {
        this.tmp_prd.nropieza = this.tmp_prd.nropieza + 1
        this.tmp_prd.cantSelect = this.tmp_prd.nropieza * this.tmp_prd.medidacorte;
      } else {
        this.tmp_prd.cantSelect = this.tmp_prd.cantSelect + 1
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, price, this.nroDecimal));
      }
    }
  }

  public calculateTotnModal_KeyEnter(cant: number, price: number) {
    this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(cant, price, this.nroDecimal));
  }

  public changecalCantReal_keyEnter(dataPrd: any) {
    this.tmp_prd.cantSelect = this.mf.roundDecimals((dataPrd.nropieza * dataPrd.medidacorte), this.nroDecimalpreci);
    this.tmp_prd.umed = 'PZA';
    this.tmp_prd.undConvPres = dataPrd.medidacorte;
    this.tmp_prd.priceOLD = this.localprice;
    switch (dataPrd.UmedSelect) {
      case 'MM':
        const tmp_priceu_mm = this.localprice * this.tmp_prd.medidacorte;
        this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(tmp_priceu_mm, this.nroDecimalpreci));
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, tmp_priceu_mm, this.nroDecimal));
        this.tmp_prd.ProducSelect = `${this.nameprd} - ${this.tmp_prd.medidacorte}`;
        break;

      case 'RIB':
        const tmp_price_rib = this.localprice * this.tmp_prd.medidacorte;
        this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(tmp_price_rib, this.nroDecimalpreci));
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, tmp_price_rib, this.nroDecimal));
        this.tmp_prd.ProducSelect = `${this.nameprd} - ${this.tmp_prd.medidacorte}`;
        break;

      case 'PUL':
        const tmp_price_pul = this.localprice * this.tmp_prd.medidacorte;
        this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(tmp_price_pul, this.nroDecimalpreci));
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, tmp_price_pul, this.nroDecimal));
        this.tmp_prd.ProducSelect = this.nameprd + "-" + (this.tmp_prd.medidacorte * 100);
        break;

      case 'MTS':
        const tmp_price_mts = this.localprice;
        this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(tmp_price_mts, this.nroDecimalpreci));
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, tmp_price_mts, this.nroDecimal));
        this.tmp_prd.ProducSelect = this.nameprd;
        break;

      default:
        const tmp_price = this.localprice;
        this.tmp_prd.priceSelect = this.mf.getConvertDecimal(this.mf.roundDecimals(tmp_price, this.nroDecimalpreci));
        this.tmp_prd.import = this.mf.getConvertDecimal(this.mf.gettotalFilaCar(this.tmp_prd.cantSelect, tmp_price, this.nroDecimal));
        this.tmp_prd.ProducSelect = this.nameprd;
        break;
    }
  }

  public addShopProduct() {
    this.dataPrdSelect.emit(this.tmp_prd);
    this.modalService.dismissAll();
  }

}
