import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class ProductoByFilterFind extends Model {
  public idcodi: string;
  public codigo: string;
  public producto: string;
  public marca: string;
  public codmarca: string;
  public codalm: string;
  public codcli: string;
  public codlocal: string;
  public codcdv: string;
  public codusu: string;
  public tipoquery: number;

  constructor(data?: object) {
    super(data);
    if (isString(this.idcodi) || isString(this.codigo) || isString(this.producto) || isString(this.codmarca)) {
      this.idcodi = this.idcodi || '';
      this.codigo = this.codigo || '';
      this.producto = this.producto || '';
      this.marca = this.marca || '';
      this.codmarca = this.codmarca || '';
      this.codalm = this.codalm || '';
      this.codcli = this.codcli || 'C00000';
      this.codlocal = this.codlocal || '001';
      this.codcdv =  this.codcdv || '01';
      this.codusu =  this.codusu || '050';
      this.tipoquery = this.tipoquery || 5;
    }
  }

  public static cast(data: object): ProductoByFilterFind {
    return new ProductoByFilterFind(data);
  }

  public static casts(data: object[]): ProductoByFilterFind[] {
    return map(data, c => new ProductoByFilterFind(c));
  }
}

export class ProductByIdFind extends Model {
  public idcodi: string;
  public codalm: string;
  public codcli: string;
  public codlocal: string;
  public codcdv: string;
  public tipoquery: number;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductByIdFind {
    return new ProductByIdFind(data);
  }

  public static casts(data: object[]): ProductByIdFind[] {
    return map(data, c => new ProductByIdFind(c));
  }
}

