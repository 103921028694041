import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Zona extends Model {
  public CodZon: string;
  public NomZon: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Zona {
    return new Zona(data);
  }

  public static casts(data: object[]): Zona[] {
    return map(data, c => new Zona(c));
  }
}
