import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from './core/services/config.service';
import { themeWeb } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'ecommerce-store';

  constructor(
    private translate: TranslateService,
    private _configFile: ConfigurationService
  ) {
    this.changeTheme(this._configFile.result.theme);
  }

  public ngOnInit() {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  private changeTheme(theme: themeWeb[]) {
    if (theme.length > 0) {
      theme.forEach(x => {
        document.documentElement.style.setProperty(x.id, x.valor);
        //-----------------------------------------------------
        // const height = imgHeight;
        // const img = Number(height.substr(0, height.length - 2)) - 10;
        // const imgH = img + height.slice(-2);
        // document.documentElement.style.setProperty('--image-height-2', imgH);
      })
    } else {
      console.log("No existe colores del tema establecido...");
    }
  }

}
