import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Distrito extends Model {
  public Id: number;
  public CodPai: string;
  public CodDep: string;
  public CodPro: string;
  public CodDis: string;
  public NomDis: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Distrito {
    return new Distrito(data);
  }

  public static casts(data: object[]): Distrito[] {
    return map(data, c => new Distrito(c));
  }
}
