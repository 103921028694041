import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';
import {ClientAlias} from './client-alias';
import {ClientLinCre} from './client-lin-cre';

export class ClientQuery extends Model {
  public message: string;
  public data: Client;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ClientQuery {
    return new ClientQuery(data);
  }

  public static casts(data: object[]): ClientQuery[] {
    return map(data, c => new ClientQuery(c));
  }
}

export class Client extends Model {
  public CodCli: string;
  public NomCli: string;
  public RucCli: string;
  public DirCli: string;
  public NroDni: string;
  public DirEnt: string;
  public TelCli: string;

  public CodPai: string;
  public CodDep: string;
  public CodPro: string;
  public CodDis: string;

  public CodZon: string;
  public CodCat: string;
  public CodAct: string;
  public CodPos: string;
  public TipoCl: string;

  public CodVen: string;
  public VenSup: string;

  public CodCob: string;
  public CodCdv: string;
  public Estado: number;
  public FlagLinCre: number;
  public MonedaLinCre: string;
  public MontoLinCre: number;
  public AddLinCre: number;
  public FecAprLinCre: Date;

  public DiaPag: string;
  public HrsPag: string;

  public Email: string;
  public EmailFe1: string;
  public EmailFe2: string;

  public NomAva: string;
  public DirAva: string;
  public RucAva: string;
  public TelAva: string;

  public IdGrupoEmp: number;
  public CodLisPre: string;
  public TipoDocSnt: string;
  public Obse: string;
  public CodTifSnt: string;
  public GPSLat: string;
  public GPSLng: string;

  // public List<Contacto>Contacto: string;

  // public Alias: ClientAlias;
  public Alias: any;

  // public DireccionEntrega: ClientAlias;

  public DireccionEntrega: any;
  public LineaCredito: ClientLinCre;
  // public AuditoriaTran Auditoria: string;


  public Contacto = []
  public Auditoria: ClientAuditoria

  // Datos adicionales
  public NomVen: string;
  public NomPai: string;
  public NomDep: string;
  public NomPro: string;
  public NomDis: string;
  public NomZon: string;
  public NomCat: string;
  public NomAct: string;
  public NomTcl: string;
  public NomCdv: string;
  public NomGruEmp: string;

  constructor(data?: object) {
    super(data);
    this.CodCli = this.CodCli || '';
    this.NomCli = this.NomCli || '';
    this.RucCli = this.RucCli || '';
    this.DirCli = this.DirCli || '';
    this.NroDni = this.NroDni || '';
    this.DirEnt = this.DirEnt || '';
    this.TelCli = this.TelCli || '';
    this.CodPai = this.CodPai || '01';
    this.CodDep = this.CodDep || '';
    this.CodPro = this.CodPro || '';
    this.CodDis = this.CodDis || '';

    this.CodZon = this.CodDis || '01';
    this.CodCat = this.CodCat || '01';
    this.CodAct = this.CodAct || '01';
    this.CodPos = this.CodPos || '01';
    this.TipoCl = this.TipoCl || 'C';

    this.CodVen = this.CodVen || 'V0000';
    this.VenSup = this.VenSup || 'V0000';

    this.CodCob = this.CodCob || 'V0000';
    this.CodCdv = this.CodCdv || '01';
    this.Estado = this.Estado || 1;
    this.FlagLinCre = this.FlagLinCre || 0;
    this.MonedaLinCre = this.MonedaLinCre || 'S';
    this.MontoLinCre = this.MontoLinCre || 0.0;
    this.AddLinCre = this.AddLinCre || 0.0;
    this.FecAprLinCre = this.FecAprLinCre || new Date();

    this.DiaPag = 'Lunes, Martes, Miércoles, Jueves, Viernes, Sábado, Domingo';
    this.HrsPag = '01:20';

    this.Email = this.Email || '';
    this.EmailFe1 = this.EmailFe1 || '';
    this.EmailFe2 = this.EmailFe2 || '';

    this.NomAva = this.NomAva || '';
    this.DirAva = this.DirAva || '';
    this.RucAva = this.RucAva || '';
    this.TelAva = this.TelAva || '';

    this.IdGrupoEmp = this.IdGrupoEmp || 1;
    this.CodLisPre = this.CodLisPre || '1';
    this.TipoDocSnt = this.TipoDocSnt || '06';
    this.Obse = this.Obse || 'más información';
    this.CodTifSnt = this.CodTifSnt || '01';
    this.GPSLat = this.GPSLat || '';
    this.GPSLng = this.GPSLng || '';

    this.Alias = this.Alias || [];

    this.DireccionEntrega = this.DireccionEntrega || [];
    this.LineaCredito =  this.LineaCredito || null ;
    this.Auditoria = this.Auditoria || new ClientAuditoria();
  }

  public static cast(data: object): Client {
    return new Client(data);
  }

  public static casts(data: object[]): Client[] {
    return map(data, c => new Client(c));
  }
}


export class ClientAuditoria extends Model {
  public IdAuditoria: number;
  public Cdocu: string;
  public Ndocu: string;
  public Operacion: string;
  public UsuarioWin: string;
  public UsuarioNav: string;
  public NombrePc: string;
  public Caption: string;
  public WonTop: string;

  constructor(data?: object) {
    super(data);
    this.IdAuditoria = this.IdAuditoria || 0;
    this.Cdocu = this.Cdocu || 'CLI';
    this.Ndocu = this.Ndocu || '';
    this.Operacion = this.Operacion || 'CREADO';
    this.UsuarioWin = this.UsuarioWin || '';
    this.UsuarioNav = this.UsuarioNav || 'Navasoft';
    this.NombrePc = this.NombrePc || 'b1a9b1be5631729e';
    this.Caption = this.Caption || '';
    this.WonTop = this.WonTop || '';
  }
  public static cast(data: object): ClientAuditoria {
    return new ClientAuditoria(data);
  }

  public static casts(data: object[]): ClientAuditoria[] {
    return map(data, c => new ClientAuditoria(c));
  }
}
