import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './config.service';
import { ProductByid, ProductoByFilterFind, Result, ResultProductByid } from 'src/app/shared/models';
import { Observable, Subscription } from 'rxjs';
import { UserService } from './user.service';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private subscription: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private config: ConfigurationService,
    private userService: UserService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Producto/` : this.config.result.apiUrl + `api/Producto/`;
    return apiUrl;
  }
  
  public postProductoByFilter(form: ProductoByFilterFind): Observable<ResultProductByid> {
    const endpoint = `${this.baseUrl}GetProductoByFilter`;
    return this.http.post(endpoint, form, this.apiService.requestOptions)
      .pipe(map(Result.cast));
  }

  public postProductoById(form: any): Observable<ProductByid> {
    const endpoint = `${this.baseUrl}GetProductoById`;
    return this.http.post(endpoint, form, this.apiService.requestOptions)
      .pipe(map(ProductByid.cast));
  }

  public getPresentProductByFilter(product: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetPresenProducto`;
    return this.http.post(endpoint, product, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getStockOtherWarehouse(codePrd: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetProductoOtroAlm/${codePrd}/ND`;
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(Result.cast));
  }

}
