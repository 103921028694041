import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ConfigurationService, ImageService, UserService } from 'src/app/core/services';
import { FunctionsService } from 'src/app/core/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../models';

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent implements OnInit {

  var_codUsu: string;
  var_codcdv: string;
  var_Moneda: string;
  var_Tcambio: number;
  var_nombUser: string;
  var_iduser: string;
  var_regimenSnt: number;
  var_TasaIgv: number;
  var_priceIncluIgv: number;
  titleCellPrice: string;
  nroDecimal: number;
  nroDecimalpreci: number;
  flagViewStockOtherAlms: boolean;
  titleModal: string = '';
  descriptionPrdObse: string = '';
  descriptionPrdDescrLarga: string = '';
  listImages: any[] = [];
  firstImage: string;
  big_img: string;

  @Input('dataPrd') dataPrd: any;
  @Input('namedocument') namedocument: any;
  @Output() dataPrdSelectCard: EventEmitter<any> = new EventEmitter();
  @ViewChild('contentProductDetail') ModalProductDetail: TemplateRef<any>;

  constructor(
    public configsvr: ConfigurationService,
    public imageService: ImageService,
    private userService: UserService,
    private modalService: NgbModal,
    public mf: FunctionsService
  ) {
    this.validGetUser();
  }

  ngOnInit() {
    this.nroDecimal = this.configsvr.result.nroDecimal_totals;
    this.nroDecimalpreci = this.configsvr.result.nroDecimal_price;
    this.dataPrd.pathImage = `${this.imageService.baseUrl}${this.dataPrd.codigo}_1.jpg`;
    this.flagViewStockOtherAlms = this.configsvr.result.flagViewStockOtherAlms;
    this.listImages = this.getListaImages(this.dataPrd.codigo);
    this.firstImage = this.listImages[0];
    this.big_img = this.firstImage;
  }

  public onclickViewIMG(nameImg: string) {
    this.big_img = nameImg;
    this.firstImage = nameImg;
  }

  public getListaImages(codigo: string) {
    let listImgs: any[] = [];
    for (let i = 1; i < 6; i++) {
      listImgs.push(`${codigo}_${i}.jpg`);
    }
    return listImgs;
  }

  public validGetUser() {
    this.userService.getUserLogs().subscribe((res: User) => {
      if (res) {
        this.var_nombUser = `${res.nomUsu} ${res.apeUsu}`;
        this.var_codUsu = res.codUsu;
        this.var_iduser = String(res.idUsu);
        this.var_codcdv = res.codCdv;
        this.var_regimenSnt = res.regimenSnt;
        this.var_Moneda = res.moneda;
        this.var_Tcambio = res.tcambio;
        this.var_TasaIgv = res.tasaIgv;
        this.var_priceIncluIgv = res.precioIncluyeIgv;
        this.titleCellPrice = (this.var_priceIncluIgv === 1) ? 'Precio Venta' : 'Valor Venta';
      }
    })
  }

  public get_priceLista_tbl(product: any) {
    let importe: any = this.mf.getpriceByMone(product?.mone, product?.pvns, product?.pvus, this.nroDecimal, this.var_priceIncluIgv, product?.vvns, product?.vvus);
    return this.mf.getConvertDecimal(importe);
  }

  public get_importDsct_tbl(product: any) {
    let price: any = this.mf.getpriceByMone(product?.mone, product?.pvns, product?.pvus, this.nroDecimal, this.var_priceIncluIgv, product?.vvns, product?.vvus);
    let importeDsct: any = this.mf.getDescuento(price, product?.dsctoDefault, this.nroDecimal);
    return this.mf.getConvertDecimal(importeDsct);
  }

  public get_priceDsct_tbl(product: any) {
    let price: any = this.mf.getpriceByMone(product?.mone, product?.pvns, product?.pvus, this.nroDecimal, this.var_priceIncluIgv, product?.vvns, product?.vvus);
    let priceDsct: any = this.mf.getPriceBydesct(price, product?.dsctoDefault, this.nroDecimal);
    return this.mf.getConvertDecimal(priceDsct);
  }

  public addShopProduct($event: any) {
    this.dataPrdSelectCard.emit($event);
  }

  public goViewSingleProduct(dataPrd: any) {
    this.titleModal = dataPrd?.descr;
    let configModal: any = { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' };
    this.modalService.open(this.ModalProductDetail, configModal);
  }

}
