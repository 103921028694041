import { Injectable } from '@angular/core';
import { Client, ClientQuery } from 'src/app/shared/models';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient,
    private config: ConfigurationService,
    private apiService: ApiService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined ) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Cliente/` : this.config.result.apiUrl + `api/Cliente/`;
    return apiUrl;
  }

  public postRegistryClient(cliente: Client): Observable<object> {
    const endpoint = `${this.baseUrl}PostCliente`;
    return this.http.post(endpoint, cliente, this.apiService.requestOptions).pipe(map((res: Response) => res));
  }

  public getClientByFilter(cliente: any): Observable<ClientQuery> {
    const endpoint = `${this.baseUrl}GetClienteById`;
    return this.http.post(endpoint, cliente, this.apiService.requestOptions).pipe(map(ClientQuery.cast));
  }

  public getClientByFilterOLD(cliente: any): Observable<object> {
    const endpoint = `${this.baseUrl}GetClienteById`;
    return this.http.post(endpoint, cliente, this.apiService.requestOptions).pipe(map((res: Response) => res));
  }

}
