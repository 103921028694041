import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public httpErrorHandler(response: HttpErrorResponse) {
    console.log('ERROR::STATUS:::::' + response.status);
    console.log('ERROR::STATUS TEXT:::::' + response.statusText);
    if (response.status > 400 && response.status < 500) {
      console.log('ERROR:::' + response.status);
      return throwError('not found');
    } else {
      if (response.status === 504 || response.status === 502 || response.status === 503) {
        return throwError('not found');
      } else {
        return throwError('not found');
      }
    }
  }
}
