import {Model} from './model';
import {User} from './user';
import {isString, map, pickBy} from 'lodash';

export class Result extends Model {
  public message: string;
  public data: any;
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Result {
    return new Result(data);
  }

  public static casts(data: object[]): Result[] {
    return map(data, u => new Result(u));
  }
}
