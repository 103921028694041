import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { AnimationService } from 'css-animator';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbButtonsModule, NgbDropdownModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

/****=> MIS COMPONENTES ***************/
import { CountProductsSearchComponent } from './count-products-search/count-products-search.component';
import { ModalStocWarehouseComponent } from './modal-stoc-warehouse/modal-stoc-warehouse.component';
import { ModalSelectProductComponent } from './modal-select-product/modal-select-product.component';
import { ModalMessageErrorComponent } from './modal-message-error/modal-message-error.component';
import { ModalImgProductComponent } from './modal-img-product/modal-img-product.component';
import { ModalDocPendingComponent } from './modal-doc-pending/modal-doc-pending.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { ModalFullImageComponent } from './modal-full-image/modal-full-image.component';
import { DataViewClientComponent } from './data-view-client/data-view-client.component';
import { ModalSendMailComponent } from './modal-send-mail/modal-send-mail.component';
import { ModalCarShopComponent } from './modal-car-shop/modal-car-shop.component';
import { BannerResultComponent } from './banner-result/banner-result.component';
import { SelectMarksComponent } from './select-marks/select-marks.component';
import { AlertSearchComponent } from './alert-search/alert-search.component';
import { CardProductComponent } from './card-product/card-product.component';
import { ZnsearchComponent } from './znsearch/znsearch.component';
import { LayoutsModule } from './layouts/layouts.module';
import { MapsComponent } from './maps/maps.component';

/****=> PIPES ***************/
import { FilterArrayPipe } from '../pipes/filter-array.pipe';


const components = [
  AlertSearchComponent,
  BannerResultComponent,
  CountProductsSearchComponent,
  DataViewClientComponent,
  MapsComponent,
  ModalCarShopComponent,
  ModalDocPendingComponent,
  ModalImgProductComponent,
  ModalSelectProductComponent,
  ModalSendMailComponent,
  ModalStocWarehouseComponent,
  ProgressSpinnerComponent,
  SelectMarksComponent,
  ZnsearchComponent,
  ModalMessageErrorComponent,
  ModalFullImageComponent,
  CardProductComponent
];

const pipes = [
  FilterArrayPipe
];

@NgModule({
  declarations: [
    components,
    pipes
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LayoutsModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    NgSelectModule,
    NgbModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule
  ],
  exports: [
    components,
    pipes
  ],
  providers: [
    AnimationService
  ]
})
export class SharedComponentsModule { }
