import {Model} from './model';
import {each, isNumber, isString, map, reduce} from 'lodash';


export class Tipodespacho extends Model {
  public idDespacho: number;
  public despacho: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Tipodespacho {
    return new Tipodespacho(data);
  }

  public static casts(data: object[]): Tipodespacho[] {
    return map(data, u => new Tipodespacho(u));
  }
}
