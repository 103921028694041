import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Local extends Model {
  public CodLoc: string;
  public NomLoc: string;
  public CodSuc: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Local {
    return new Local(data);
  }

  public static casts(data: object[]): Local[] {
    return map(data, c => new Local(c));
  }
}
