import { Injectable } from '@angular/core';
import { forEach, filter, isString } from 'lodash';

import {ConfigurationService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public NAMESPACE: string;
  public HOME_TOKEN_PREFERENCE: string;
  public USER_TOKEN_PREFERENCE: string;
  public USER_PROFILE_PREFERENCE: string;
  public HOME_STORE_ECOMMERCE: string;

  constructor(private config: ConfigurationService) {
    this.NAMESPACE = `${this.config.result.envName}-HSYS-ECOMMERCE`;
    // Token
    this.HOME_TOKEN_PREFERENCE = `${this.NAMESPACE}_HOME-TOKEN-PREFERENCE`;
    this.USER_TOKEN_PREFERENCE = `${this.NAMESPACE}_HOME-TOKEN-PREFERENCE`;
    this.USER_PROFILE_PREFERENCE = `${this.NAMESPACE}_HOME-PROFILE-PREFERENCE`;
    // Home
    this.HOME_STORE_ECOMMERCE = `${this.NAMESPACE}_HOME-STORE-ECOMMERCE`;
  }

  private get possibleKeys() {
    return filter(Object.values(this), isString);
  }

  private isValidKey(key: string): boolean {
    return this.possibleKeys.indexOf(key) < 0;
  }

  public setStorage(key: string, value, stringify: boolean = true) {
    if (this.isValidKey(key)) {
      throw new Error('Invalid key.');
    }
    if (stringify) {
      localStorage[key] = JSON.stringify(value);
    } else {
      localStorage[key] = value;
    }
  }

  public getStorage(key: string, parse: boolean = true) {
    const data = localStorage[key];
    if (data === undefined) {
      console.warn(`undefined data found for key: ${key}`);
      return null;
    } else if (parse && data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  }

  public clearStorage(key: string) {
    if (this.getStorage(key)) {
      localStorage.removeItem(key);
    }
  }

  public clearStorageByKeys(keys: string[]) {
    forEach(keys, value => {
      this.clearStorage(value);
    });
  }

  public clearStorageAll() {
    localStorage.clear();
  }
}
