import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {NavigationService} from '../../core/services/navigation.service';
import {ActivatedRouteSnapshot, Router, RouterState, RouterStateSnapshot} from '@angular/router';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appMenuOutside]'
})
export class MenuOutsideDirective {

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();
  private subscriptions: Subscription = new Subscription();
  isMenuOutside: boolean;
  isDisplayMenu: boolean;
  selectLink: string;

  constructor(
    private elementRef: ElementRef,
    private navigationService: NavigationService,
    private router: Router
  ) {

    this.subscriptions.add(
      this.navigationService.isMenuOutside.subscribe(
        (isMenuOutside: boolean) => (this.isMenuOutside = isMenuOutside)
      ));

    this.subscriptions.add(
      this.navigationService.isDisplayMenu.subscribe(
        (isDisplayMenu: boolean) => (this.isDisplayMenu = isDisplayMenu)
      ));
  }

  @HostListener('mouseenter')
  public onMouseEnter(event: MouseEvent) {
    this.navigationService.isMenuOutside.next(false);
    this.navigationService.isDisplayMenu.next(true);
    this.clickOutside.emit(event);
  }

  @HostListener('mouseleave') onMouseLeave() {
    // console.log('mouseleave');
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onMouseClick(event: MouseEvent, targetElement: HTMLElement) {

    const state: RouterState = this.router.routerState;
    const url: string = _.join(_.split(state.snapshot.url, '/', 2), '');
    const link: string = _.isUndefined(this.selectLink) ? url : this.selectLink;

    if (_.includes(['company', 'shop'], url)) {
      if (this.isMenuOutside && this.isDisplayMenu) {
        this.navigationService.isDisplayMenu.next(false);
      } else if (this.isMenuOutside && !this.isDisplayMenu) {
        // this.navigationService.isMenuOutside.next(false);
      } else if (this.isDisplayMenu){
        this.navigationService.isDisplayMenu.next(false);
      }
    }
    this.selectLink = url;
    this.clickOutside.emit(event);
  }
}
