import { Injectable } from '@angular/core';
import { ConfigurationService } from './config.service';
import { Result } from '../../shared/models/result';
import { EmailSend } from 'src/app/shared/models';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/tools/` : this.config.result.apiUrl + `api/tools/`;
    return apiUrl;
  }

  public postSendToMail(emailSend: EmailSend): Observable<Result> {
    const endpoint = `${this.baseUrl}SendMail`;
    return this.http.post(endpoint, emailSend).pipe(map(Result.cast));
  }
}
