import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { NavigationService } from '../services/navigation.service';
import { join, split } from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLoadUser: boolean;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private userService: UserService,
    private navigationService: NavigationService
  ) {

    this.subscriptions.add(this.userService.isLoadUser.subscribe((isLoadUser: boolean) => (this.isLoadUser = isLoadUser)));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.navigationService.isMenuOutside.next(false);
    if (this.isLoadUser) {
      return true;
    }
    this.router.navigate(['/sessions/signin']);
    return false;
  }

}
