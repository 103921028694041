import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Provincia extends Model {
  public Id: number;
  public CodPai: string;
  public CodDep: string;
  public CodPro: string;
  public NomPro: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Provincia {
    return new Provincia(data);
  }

  public static casts(data: object[]): Provincia[] {
    return map(data, c => new Provincia(c));
  }
}
