import {map } from 'lodash';

import {Model} from './model';

export class Token extends Model {
  public message: string;
  public data: TokenData;
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Token {
    return new Token(data);
  }

  public static casts(data: object[]): Token[] {
    return map(data, u => new Token(u));
  }
}

export class TokenData extends Model {

  public moneda: string;
  public tasaIgv: number;
  public tcambio: number;
  public token: ChildrenToken;
  public expiration: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): TokenData {
    return new TokenData(data);
  }

  public static casts(data: object[]): TokenData[] {
    return map(data, u => new TokenData(u));
  }
}

export class ChildrenToken extends Model {
  public token: string;
  public expiration: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ChildrenToken {
    return new ChildrenToken(data);
  }

  public static casts(data: object[]): ChildrenToken[] {
    return map(data, u => new ChildrenToken(u));
  }
}
