import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsService } from 'src/app/core/helpers';
import { ConfigurationService, ImageService } from 'src/app/core/services';

@Component({
  selector: 'app-modal-full-image',
  templateUrl: './modal-full-image.component.html',
  styleUrls: ['./modal-full-image.component.scss']
})
export class ModalFullImageComponent implements OnInit {

  @Input() prd_name: string;
  @Input() prd_img: string;
  @Input() prd_codigo: string;
  listImages: any[] = [];
  firstImage: string;
  big_img: string;

  constructor(
    public configsvr: ConfigurationService,
    public imageService: ImageService,
    public configModal: NgbModalConfig,
    public activeModal: NgbActiveModal,
    public mf: FunctionsService,
  ) {
    configModal.backdrop = 'static';
    configModal.keyboard = false;
  }

  ngOnInit(): void {
    this.listImages = this.getListaImages(this.prd_codigo);
    this.firstImage = this.listImages[0];
    this.big_img = this.firstImage;
  }

  public getListaImages(codigo: string) {
    let listImgs: any[] = [];

    for (let i = 1; i < 6; i++) {
      listImgs.push(`${codigo}_${i}.jpg`);
    }

    return listImgs;
  }

  public onclickViewIMG(nameImg: string) {
    this.big_img = nameImg;
    this.firstImage = nameImg;
  }

}
