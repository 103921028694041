import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService, CotizacionService, MstPedidoService, NavigationService, SearchService, UserService } from 'src/app/core/services';
import { ProductLogs, User } from 'src/app/shared/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { FunctionsService } from 'src/app/core/helpers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isVisible: boolean;
  isLogin: boolean;
  isMenuOutside: boolean;
  isLoadUser: boolean;
  checkout: boolean;
  isSearch: string;
  loadingcta: boolean;
  user: User;
  public category: boolean;
  public codSub: string;
  public codFam: string;
  flagViewCtrl: boolean;
  public productLogs: ProductLogs;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    public configsvr: ConfigurationService,
    public navigationService: NavigationService,
    private userService: UserService,
    private modalService: NgbModal,
    private orderService: MstPedidoService,
    private cotizacionService: CotizacionService,
    public mf: FunctionsService
  ) {
    this.subscription.add(this.userService.isLoadUser.subscribe((isLoadUser: boolean) => (this.isLoadUser = isLoadUser)));
    this.subscription.add(this.userService.getUserLogs().subscribe(res => { this.user = res }));
    this.subscription.add(this.navigationService.isMenuOutside.subscribe((isMenuOutside: boolean) => (this.isMenuOutside = isMenuOutside)));
    this.flagViewCtrl = this.mf.getValueFlagCtrlByTypeCompany(this.configsvr.result.typeCompany);
  }

  ngOnInit(): void {
    if(!this.isLoadUser){
      this.router.navigate(['/sessions/signin']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onClickOutsideMenu(isOption: boolean = false) {
    if (!isOption) {
      this.isVisible = !this.isMenuOutside ? true : !this.isVisible;
      this.isLogin = false;
    } else {
      this.isLogin = !this.isMenuOutside ? true : !this.isLogin;
      this.isVisible = false;
    }
    this.navigationService.isMenuOutside.next(true);
    this.navigationService.isDisplayMenu.next(true);
  }

  public onRedirectView(content: any, tipoUsu: number, ruta: string) {
    if (tipoUsu === 2) {
      this.navigationService.isMenuOutside.next(false);
      this.router.navigate([ruta]);
    } else {
      this.modalService.open(content, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
    }
  }

  public closeSession(){
    this.orderService.setFilterFormPed(null);
    this.orderService.setListOrders(null);
    this.cotizacionService.setFilterFormCot(null);
    this.cotizacionService.seListQuotes(null);
    this.router.navigate(['/sessions']);
  }

}
