import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Login, Token, TokenData } from 'src/app/shared/models';
import { LocalStorageService } from './local-storage.service';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private token: string;
  public userLogin = new BehaviorSubject<TokenData>(null);
  public refreshTokenLogin: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public userLogged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private localStorageService: LocalStorageService,
    public router: Router
  ) { }


  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/login/` : this.config.result.apiUrl + `api/login/`;
    return apiUrl;
  }

  public getLoginPreference(login: Login): Observable<object> {
    const endpoint = `${this.baseUrl}GetUsuario`;
    return this.http.post(endpoint, login).pipe(map((res: Response) => res));
  }

  public processLogin(): Subscription {
    const login = new Login();
    return this.getLoginPreference(login)
      .subscribe((token: Token) => {
        if (token.success) {
          this.userLogin.next(token.data);
          this.refreshTokenLogin.next(token.data.token.token);
          this.userLogged.next(true);
          this.token = token.data.token.token;
        }
      });
  }

  refreshToken() {
    const login = new Login();
    const endpoint = `${this.baseUrl}GetUsuario`;
    return this.http.post(endpoint, login)
      .pipe(tap((token: Token) => { }
      ));
  }

  public getLoadingLogin(): string {
    this.subscriptions.add(this.processLogin());
    return this.token || null;
  }

  public saveTokenPreference(preference: string): void {
    if (preference) {
      this.localStorageService.setStorage(this.localStorageService.HOME_TOKEN_PREFERENCE, preference);
    }
  }

  public loadTokenPreference(): string {
    return this.getLoadingLogin();
  }

  public clearTokenPreference() {
    this.localStorageService.clearStorage(this.localStorageService.HOME_TOKEN_PREFERENCE);
  }
}
