import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Vendedor extends Model {
  public CodVen: string;
  public NomVen: string;
  public CodAdm: number;
  public Estado: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Vendedor {
    return new Vendedor(data);
  }

  public static casts(data: object[]): Vendedor[] {
    return map(data, c => new Vendedor(c));
  }
}
