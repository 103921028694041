import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ClientService, ConfigurationService } from 'src/app/core/services';
import { FunctionsService } from 'src/app/core/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Result } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-car-shop',
  templateUrl: './modal-car-shop.component.html',
  styleUrls: ['./modal-car-shop.component.scss']
})
export class ModalCarShopComponent implements OnInit {

  @Input('codUsu') var_codUsu: string;
  @Input('dataProduct') dataProduct: any;
  @Input('cantItem') cantItem: number;
  @Input('regimenSnt') var_regimenSnt: number;
  @Input('TasaIgv') var_TasaIgv: number;
  @Input('moneda') var_Moneda: string;
  @Input('tipodocu') var_docu: string;
  @Input('priceIncluIgv') priceIncluIgv: number;
  @Output() flagShopCar: EventEmitter<boolean>;

  ClientQuery: any;
  nroDecimal: number;
  totalCarShop: number = 0.00;
  titleColumPrice: string;
  loadingAlertCesta: boolean;
  flagViewBtnShop: boolean;
  loadingAlertCestaText: string;
  dataMsjError: any = {};
  @ViewChild('modalError') modalError: TemplateRef<any>;
  @ViewChild('modalCLEAR') modalCLEAR: TemplateRef<any>;

  constructor(
    public mf: FunctionsService,
    public configsvr: ConfigurationService,
    public clientService: ClientService,
    private modalService: NgbModal,
  ) {
    this.flagShopCar = new EventEmitter();
  }

  ngOnInit(): void {
    this.nroDecimal = this.configsvr.result.nroDecimal_totals;
  }

  public openModalCarShop(contentCTA: any) {
    this.flagViewBtnShop = (this.cantItem > 0 && this.dataProduct.length > 0) ? false : true;
    this.titleColumPrice = (this.priceIncluIgv === 1) ? 'PRE. UNI. DSC.' : 'VAL. UNI. DSC.';
    this.getClientByID(this.var_codUsu, contentCTA);
  }


  private getClientByID(codUsu: string, contentCTA: any): Subscription {
    const data = this.mf.getDataFilterClient(codUsu);
    return this.clientService.getClientByFilterOLD(data).subscribe((res: Result) => {
      if (res.success) {
        this.ClientQuery = res;
        this.getTotalgeneralOrder(this.dataProduct);
        this.modalService.open(contentCTA, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
      } else {
        this.setDataError(res, this.modalError);
        this.renderAlertCar('ERROR AL LEER LOS DATOS DEL CLIENTE - CLIENTE NO EXISTE')
      }
    })
  }

  private setDataError(datamsj: any, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    this.modalService.open(nameModal, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
  }

  private getTotalgeneralOrder(data: any) {
    this.totalCarShop = this.mf.getTotalCarShop(data, this.nroDecimal, this.priceIncluIgv);
  }

  public clearShop() {
    let configModal: any = { size: 'sm', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' }
    this.modalService.open(this.modalCLEAR, configModal).result.then((result) => {
    }, (reason) => {
      this.dataProduct.splice(0, this.dataProduct.length);
      this.cantItem = this.dataProduct.length;
      this.totalCarShop = 0.00;
      this.flagViewBtnShop = (this.cantItem > 0 && this.dataProduct.length > 0) ? false : true;
      this.renderAlertCar('Carrito limpio...');
    })
  }

  public deleteItemShop(i: number) {
    this.dataProduct.splice(i, 1);
    this.getTotalgeneralOrder(this.dataProduct);
    this.cantItem = this.dataProduct.length;
    this.flagViewBtnShop = (this.cantItem > 0 && this.dataProduct.length > 0) ? false : true;
  }

  public setIncrease_cant(i: number) {
    let newcant = this.dataProduct[i].cantSelect;
    const price = this.dataProduct[i].priceSelect;
    const aigv = this.dataProduct[i].aigv;
    newcant = newcant + 1;
    const totalg = this.mf.gettotalFilaCar(newcant, price, this.nroDecimal);
    this.dataProduct[i].cantSelect = newcant;
    if (this.priceIncluIgv === 1) {
      this.dataProduct[i].totn = totalg;
      this.dataProduct[i].tota = this.mf.getTotalBaseImpo(this.dataProduct[i].totn, aigv, this.var_regimenSnt, this.nroDecimal, this.var_TasaIgv);
    } else {
      this.dataProduct[i].tota = totalg;
      this.dataProduct[i].totn = this.mf.getTotnRegister(this.dataProduct[i].tota, aigv, this.var_regimenSnt, this.nroDecimal, this.var_TasaIgv);
    }
    this.getTotalgeneralOrder(this.dataProduct);
  }

  public setDecrease_cant(i: number) {
    this.loadingAlertCesta = false
    this.loadingAlertCestaText = '';
    let newcant = this.dataProduct[i].cantSelect;
    const price = this.dataProduct[i].priceSelect;
    const aigv = this.dataProduct[i].aigv;
    if (newcant > 1) {
      newcant = newcant - 1;
      const totalg = this.mf.gettotalFilaCar(newcant, price, this.nroDecimal);
      this.dataProduct[i].cantSelect = newcant;
      if (this.priceIncluIgv === 1) {
        this.dataProduct[i].totn = totalg;
        this.dataProduct[i].tota = this.mf.getTotalBaseImpo(this.dataProduct[i].totn, aigv, this.var_regimenSnt, this.nroDecimal, this.var_TasaIgv);
      } else {
        this.dataProduct[i].tota = totalg;
        this.dataProduct[i].totn = this.mf.getTotnRegister(this.dataProduct[i].tota, aigv, this.var_regimenSnt, this.nroDecimal, this.var_TasaIgv);
      }
      this.getTotalgeneralOrder(this.dataProduct);
    } else {
      this.renderAlertCar('Cantidad menor o igual a CERO, NO ES VALIDO')
    }
  }

  public nextProcessShop() {
    if (this.cantItem > 0 && this.totalCarShop > 0) {
      this.flagShopCar.emit(true);
    } else {
      this.flagShopCar.emit(false);
    }
  }

  /// OK
  private renderAlertCar(text: string) {
    this.loadingAlertCesta = true
    this.loadingAlertCestaText = text;
    setTimeout(() => {
      this.loadingAlertCesta = false
    }, 3500);
  }

}
