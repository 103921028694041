import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class EmailSend extends Model {
  public Cdocu: string;
  public Ndocu: string;
  public BodyHtml: string;
  public TypeReport: number;
  public IdReport: number;
  public AttachDocument: number;
  public MailIncludeInfo: number;
  public PageSize: string;
  public PageOrientation: number;
  public AnchoPixeles: number;
  public SmtpFrom: string;
  public FromAddress: string;
  public EmailAddress: string;
  public Subject: string;
  public Content: string;
  public Firma: string;

  constructor(data?: object) {
    super(data);

    this.Cdocu = this.Cdocu || '';
    this.Ndocu = this.Ndocu || '';
    this.BodyHtml = this.BodyHtml || '';
    this.TypeReport = this.TypeReport || 1;
    this.IdReport = this.IdReport || 0;
    this.AttachDocument = this.AttachDocument || 0;
    this.MailIncludeInfo = this.MailIncludeInfo || 0;
    this.PageSize = this.PageSize || 'A4';
    this.PageOrientation = this.PageOrientation || 0;
    this.AnchoPixeles = this.AnchoPixeles || 1200;
    this.SmtpFrom = this.SmtpFrom || 'ventas';
    this.Firma = this.Firma || 'eCommerce';
  }

  public static cast(data: object): EmailSend {
    return new EmailSend(data);
  }

  public static casts(data: object[]): EmailSend[] {
    return map(data, c => new EmailSend(c));
  }
}
