import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';
import {DtlPedido} from './dtl-pedido';
import {ClientAuditoria} from './client';
import * as moment from 'moment';

export class Pedido extends Model {
  public Fecha: any;
  public Cdocu: string;
  public Ndocu: string;
  public CodCli: string;
  public NomCli: string;
  public RucCli: string;
  public NomSol: string;
  public Crefe: string;
  public Nrefe: string;
  public Orde: string;
  public Mone: string;
  public Tcam: number;
  public Tota: number;
  public Toti: number;
  public Totn: number;
  public Flag: string;
  public CodVen: string;
  public CodCdv: string;
  public Dias: number;
  public FecEnt: any;
  public CodPto: string;
  public CodScc: string;
  public CodPos: string;
  public Operacion: string;
  public FlaApr: string;
  public FecApr: any;
  public FecAte: any;
  public Obse: string;
  public DirEnt: string;
  public IdAlias: number;
  public Frontera: number;
  public CodTar: string;
  public Origen: number;
  public TipoEntrega: number;
  public Cdge: string;
  public Ndge: string;
  public CodTra: string;
  public CodAlm: string;
  public IcbPer: number;
  public Detalle: DtlPedido[];
  public Auditoria: ClientAuditoria;

  // Campos adicionales TMP solo para edicion y consulta
  public NomEstado: string;
  public Email: string;
  public NomVen: string;
  public NomCdv: string;

  //CAMPOS PARA PASARELA DE PAGO
  public CodFdp: string;
  public codTypePay: string;
  public namePaymentGateway: string;
  public nroOperacionPay: string;
  public statusOperacionPay: string;
  public processResponsePay: string;
  public fechaPayment: any;

  // envío de correo
  public fromAddress: string;
  public firma: string;
  public SmtpFrom: string;
  public content: any;

  constructor(data?: object) {
    super(data);
    const date = new Date();
    this.Fecha = this.Fecha || new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
    this.Cdocu = this.Cdocu || '32';
    this.Ndocu = this.Ndocu || '000-00000000';
    this.CodCli = this.CodCli || '';
    this.NomCli = this.NomCli || '';
    this.RucCli = this.RucCli || '';
    this.NomSol = this.NomSol || '';
    this.Crefe = this.Crefe || '';
    this.Nrefe = this.Nrefe || '';
    this.Orde = this.Orde || '';
    this.Mone = this.Mone || 'S';
    this.Tcam = this.Tcam || 3.000;
    this.Tota = this.Tota || 0;
    this.Toti = this.Toti || 0;
    this.Totn = this.Totn || 0;
    this.Flag = this.Flag || '0';
    this.CodVen = this.CodVen || 'V0000';
    this.CodCdv = this.CodCdv || '01';
    this.Dias = this.Dias || 0;
    this.FecEnt = this.FecEnt || moment().format('YYYY-MM-DD');
    this.CodPto = this.CodPto || '01';
    this.CodScc = this.CodScc || '';
    this.CodPos = this.CodPos || '';
    this.Operacion = this.Operacion || 'N';
    this.FlaApr = this.FlaApr || '';
    this.FecApr = this.FecApr || moment().format('YYYY-MM-DD');
    this.FecAte = this.FecAte || moment().format('YYYY-MM-DD');
    this.Obse = this.Obse || '';
    this.DirEnt = this.DirEnt || '';
    this.IdAlias = this.IdAlias || 0;
    this.Frontera = this.Frontera || 0;
    this.CodTar = this.CodTar || '';
    this.Origen = this.Origen || 5;
    this.TipoEntrega = this.TipoEntrega || 0;
    this.Cdge = this.Cdge || '';
    this.Ndge = this.Ndge || '';
    this.CodTra = this.CodTra || '';
    this.CodAlm = this.CodAlm || '';
    this.IcbPer = this.IcbPer || 0.00;
    this.Email = this.Email || ''
    this.Auditoria = this.Auditoria || new ClientAuditoria();

    this.CodFdp = this.CodFdp || '';
    this.codTypePay = this.codTypePay || '';
    this.namePaymentGateway = this.namePaymentGateway || '';
    this.nroOperacionPay = this.nroOperacionPay || '';
    this.statusOperacionPay = this.statusOperacionPay || '';
    this.fechaPayment = this.fechaPayment || moment('1999-01-01').format('YYYY-MM-DD');
    this.processResponsePay = this.processResponsePay || 'C';
  }

  public static cast(data: object): Pedido {
    return new Pedido(data);
  }

  public static casts(data: object[]): Pedido[] {
    return map(data, c => new Pedido(c));
  }
}
