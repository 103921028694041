import { Injectable } from '@angular/core';
import { ConfigurationService } from './config.service';
import { UbigeoCast } from 'src/app/shared/models';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UbligeoService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private alertService: AlertService,
    private apiService: ApiService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Cliente/` : this.config.result.apiUrl + `api/Cliente/`;
    return apiUrl;
  }

  public getTblUbigeo(): Observable<UbigeoCast> {
    const endpoint = `${this.baseUrl}GetTblUbigeo`;
    // return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(UbigeoCast.cast), catchError(this.alertService.httpErrorHandler));
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(UbigeoCast.cast));
  }
}
