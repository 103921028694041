import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class ProductByid extends Model {
  public message: string;
  public data: ProductByidDesc;
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductByid {
    return new ProductByid(data);
  }

  public static casts(data: object[]): ProductByid[] {
    return map(data, c => new ProductByid(c));
  }
}

export class ResultProductByid extends Model {
  public message: string;
  public data: ProductByidDesc[];
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ResultProductByid {
    return new ResultProductByid(data);
  }

  public static casts(data: object[]): ResultProductByid[] {
    return map(data, c => new ResultProductByid(c));
  }
}

export class ProductByidDesc extends Model {
  public idCodi: string;
  public codigo: string;
  public descr: string;
  public marca: string;
  public codMarca: string;
  public clase: string;
  public umed: string;
  public stock: number;
  public mone: string;
  public aigv: string;
  public pans: number;
  public paus: number;
  public vvns: number;
  public vvus: number;
  public pvns: number;
  public pvus: number;
  public msto: string;
  public peso: number;
  public volumen: number;
  public codAlm: string;
  public codPaiPro: string;
  public codPaiOri: string;
  public descrLarga: string;
  public obse: string;
  public imagen: string;
  public codAlterno: string;
  public codParte: string;
  public docFichaTec: string;
  public docAdjunto1: string;
  public docAdjunto2: string;
  public txtAdd1: string;
  public txtAdd2: string;
  public txtAdd3: string;
  public umedMinCom: string;
  public cantMinCom: number;
  public idProducto: number;
  public flagPres: number;
  public flagRollo: number;
  public flagTramo: number;
  public flagPrdRelac: number;
  public dsctoDefault: number;
  public dsctoMaxVen: number;
  public dsctoMaxGer: number;
  public nomAlm: string;
  public nomFam: string;
  public nomSbf: string;
  public nomGrp: string;
  public estadoPrd: string;
  public notIng_mes: string;
  public orde: number;
  public presen: ProductByidPresen[];
  public prdRelac: ProductByidPrdRelac[];

  // add
  public newUmed: string;
  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductByidDesc {
    return new ProductByidDesc(data);
  }

  public static casts(data: object[]): ProductByidDesc[] {
    return map(data, c => new ProductByidDesc(c));
  }
}

export class ProductByidPresen extends Model {
  public idCodi: string;
  public tipo: string;
  public umed: string;
  public undConvPres: number;
  public pvns: number;
  public pvus: number;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductByidPresen {
    return new ProductByidPresen(data);
  }

  public static casts(data: object[]): ProductByidPresen[] {
    return map(data, c => new ProductByidPresen(c));
  }
}

export class ProductByidPrdRelac extends Model {
  public idCodi: string;
  public codigo: string;
  public descr: string;
  public marca: string;
  public umed: string;
  public mone: string;
  public pvns: number;
  public pvus: number;
  public imagen: number;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ProductByidPrdRelac {
    return new ProductByidPrdRelac(data);
  }

  public static casts(data: object[]): ProductByidPrdRelac[] {
    return map(data, c => new ProductByidPrdRelac(c));
  }
}
