import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-count-products-search',
  templateUrl: './count-products-search.component.html',
  styleUrls: ['./count-products-search.component.scss']
})
export class CountProductsSearchComponent implements OnInit {

  @Input('flagviewCount') showListaProduct: boolean;
  @Input('cantPrds') cantPro: number;

  constructor() { }

  ngOnInit(): void {
  }

}
