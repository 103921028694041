import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Pais extends Model {
  public CodPai: string;
  public NomPai: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Pais {
    return new Pais(data);
  }

  public static casts(data: object[]): Pais[] {
    return map(data, c => new Pais(c));
  }
}
