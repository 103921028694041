import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Login, User } from 'src/app/shared/models';
import { ConfigurationService } from './config.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userLogs = new BehaviorSubject<User>(null);
  public isLoadUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Cuenta/` : this.config.result.apiUrl + `api/Cuenta/`;
    return apiUrl;
  }

  public setUserLogs(user) {
    this.userLogs.next(user);
  }

  public getUserLogs() {
    return this.userLogs.asObservable();
  }

  public setLoadingUser(value: boolean): void {
    this.isLoadUser.next(value);
  }

  public getLoadingUser() {
    return this.isLoadUser.asObservable();
  }


  public getUserPreference(login: Login): Observable<object> {
    const endpoint = `${this.baseUrl}GetUsuario`;
    return this.http.post(endpoint, login, this.apiService.requestOptions).pipe(map((res: Response) => res));
  }

  public postRegistryUsuario(user: User): Observable<object> {
    const endpoint = `${this.baseUrl}PostUsuario`;
    return this.http.post(endpoint, user, this.apiService.requestOptions).pipe(map((res: Response) => res));
  }

  public postUpdateUsuario(user: User): Observable<object> {
    const endpoint = `${this.baseUrl}UpdateUsuario`;
    return this.http.post(endpoint, user, this.apiService.requestOptions).pipe(map((res: Response) => res));
  }

  public saveTokenUser(preference: string): void {
    if (preference) {
      this.localStorageService.setStorage(this.localStorageService.USER_TOKEN_PREFERENCE, preference);
    }
  }

  public saveProfileUser(preference: string): void {
    if (preference) {
      this.localStorageService.setStorage(this.localStorageService.USER_PROFILE_PREFERENCE, preference);
    }
  }

  public loadTokenUser(): string {
    return this.localStorageService.getStorage(this.localStorageService.USER_TOKEN_PREFERENCE);
  }

  public loadProfileUser(): User {
    return this.localStorageService.getStorage(this.localStorageService.USER_PROFILE_PREFERENCE);
  }

  public clearTokenProfileUser() {
    this.localStorageService.clearStorageByKeys([
      this.localStorageService.USER_TOKEN_PREFERENCE,
      this.localStorageService.USER_PROFILE_PREFERENCE
    ]);
  }
}
