import { Injectable } from '@angular/core';
import { Pedido, ProductDesc, Result, TokenData } from 'src/app/shared/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DtlPedidoService {

  public cartItems = new BehaviorSubject<ProductDesc>(null);
  public productDesc: Array<ProductDesc> = [];
  public userLogin: TokenData;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private apiService: ApiService
  ) { }

  private getUserLogin(): Subscription {
    return this.loginService.userLogin.subscribe(res => {
      if (res) {
        this.userLogin = res;
      }
    });
  }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined ) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Pedido/` : this.config.result.apiUrl + `api/Pedido/`;
    return apiUrl;
  }

  public postPostPedido(pedido: Pedido): Observable<Result> {
    const endpoint = `${this.baseUrl}PostPedido`;
    return this.http.post(endpoint, pedido, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public updatePostPedido(pedido: Pedido): Observable<Result> {
    const endpoint = `${this.baseUrl}UpdatePedido`;
    return this.http.post(endpoint, pedido, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public deletePostPedido(pedido: any): Observable<Result> {
    const endpoint = `${this.baseUrl}DeletePedido`;
    return this.http.post(endpoint, pedido, this.apiService.requestOptions).pipe(map(Result.cast));
  }

}