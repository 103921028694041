import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-view-client',
  templateUrl: './data-view-client.component.html',
  styleUrls: ['./data-view-client.component.scss']
})
export class DataViewClientComponent implements OnInit {

  @Input('nomCli') nomCli:string
  @Input('rucCli') rucCli:string
  @Input('dirCli') dirCli:string
  @Input('flagviewtip') flagviewtip:number;

  constructor() { }

  ngOnInit(): void {
  }

}
