import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsService } from 'src/app/core/helpers';
import { ToolsService } from 'src/app/core/services';
import { Result } from '../../models';

@Component({
  selector: 'app-modal-send-mail',
  templateUrl: './modal-send-mail.component.html',
  styleUrls: ['./modal-send-mail.component.scss']
})
export class ModalSendMailComponent implements OnInit {

  @Input('flagViewBTN') usercorporativo_control: boolean
  @Input('dataDoc') objDocuSelect: any = {};
  @Input('emailclient') emailcli: string;
  @Input('tipoDoc') tipoDoc: string;

  alertPND_mail: boolean;
  alertTextPND01: string;
  alertTextPND02: string;
  classAlert: string;
  dataMsjError: any = {};
  @ViewChild('modalError') modalError: TemplateRef<any>;

  @Output() flagAlertStatus: EventEmitter<number>;
  @Output() loading: EventEmitter<boolean>;

  constructor(
    public mf: FunctionsService,
    public toolsService: ToolsService,
    private modalService: NgbModal
  ) {
    this.flagAlertStatus = new EventEmitter();
    this.loading = new EventEmitter();
  }

  ngOnInit(): void {
  }

  openModalMail(content) {
    this.renderAlertEmail('', '', false, '');
    this.setDataModal(this.objDocuSelect);
    const valid = this.mf.getStatusDOC(this.objDocuSelect.flag, this.objDocuSelect.flaApr);
    switch (valid) {
      case 1:
        this.flagAlertStatus.emit(1);
        break;
      case 2:
        this.modalService.open(content, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
        break;
      case 3:
        this.modalService.open(content, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
        break;
      case 4:
        this.modalService.open(content, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
        break;
      default:
        this.flagAlertStatus.emit(0);
        break;
    }
  }

  setDataModal(obj: any) {
    const tipoDOC = this.tipoDoc;
    switch (tipoDOC) {
      case '31':
        this.objDocuSelect = {
          nrdoOrder: obj.ndocu,
          clienOrder: obj.nomCli,
          fechOrder: obj.fecha,
          montoOrer: obj.totn,
          moneOrder: obj.mone,
          flag: obj.flag,
          flaApr: obj.estado
        };
        break;

      case '32':
        this.objDocuSelect = {
          nrdoOrder: obj.ndocu,
          clienOrder: obj.nomCli,
          fechOrder: obj.fecEnt,
          montoOrer: obj.totn,
          moneOrder: obj.mone,
          flag: obj.flag,
          flaApr: obj.flaApr
        }
        break;

      default:
        this.objDocuSelect = {}
        break;
    }
  }

  private renderAlertEmail(text01: string, text02: string, flag: boolean, styleAlert: string) {
    this.alertPND_mail = flag;
    this.alertTextPND01 = text01;
    this.alertTextPND02 = text02;
    this.classAlert = styleAlert;
  }

  SendMail() {
    if (this.emailcli !== '' && this.emailcli !== undefined && this.emailcli !== null) {
      this.loading.emit(true);
      this.renderAlertEmail('ENVIANDO DOCUMENTO...!', 'Por favor espere que termine de enviar...', true, 'alert-warning');
      const data = this.mf.getDataSendMail(this.tipoDoc, this.objDocuSelect.nrdoOrder, this.emailcli);
      return this.toolsService.sendMailDocument(data).subscribe((res: Result) => {
        if (res.success) {
          setTimeout(() => {
            this.loading.emit(false);
          }, 1000);
          this.renderAlertEmail('¡CORREO ENVIADO SATISFACTORIAMENTE!', 'Por favor revisar la bandeja de entrada de su aplicativo de correos', true, 'alert-success');
        } else {
          this.setDataError(res, this.modalError);
          this.renderAlertEmail('¡ERROR AL ENVIAR EL E-MAIL!', 'Por favor verificar el correo ingresado... ', true, 'alert-danger');
          this.loading.emit(false);
        }
      })
    } else {
      this.renderAlertEmail('¡ALERTA!', 'No se a encontrado un E-mail para enviar el documento', true, 'alert-danger');
      this.loading.emit(false);
    }
  }

  private setDataError(datamsj: any, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    this.modalService.open(nameModal, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
  }


}
