import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AdminComponent } from './admin/admin.component';
import { FooterComponent } from './admin/footer/footer.component';
import { HeaderComponent } from './admin/header/header.component';
import { InputNumberDirective, MenuOutsideDirective } from '../../directives';
// import { InputNumberDirective } from '../../directives/input-number.directive';

const components = [
  AdminComponent,
  HeaderComponent,
  FooterComponent,
  MenuOutsideDirective,
  InputNumberDirective
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule
  ],
  exports: components
})
export class LayoutsModule { }
