import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './login.service';
import { Subscription } from 'rxjs';
import { isArray } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: HttpHeaders;
  private subscriptions: Subscription = new Subscription();
  private authToken: string;

  constructor(private loginService: LoginService,
    protected http: HttpClient
  ) { }

  public get requestOptions(): { headers: HttpHeaders } {
    this.subscriptions.add(
      this.loginService.refreshTokenLogin.subscribe(
        (token: string) => (this.authToken = token)
      )
    );
    const authToken = this.authToken;
    this.headers = new HttpHeaders().set('Authorization', `Bearer ${authToken}`);
    return {
      headers: this.headers
    };
  }

  /*
   * Takes an object and returns a query string, strips null,
   * undefined, and empty strings.
   * @param obj: Object
   * @return query string without the leading '?'
   */
  public objectToQueryString(obj: object = {}): string {
    const object = obj || {};
    const params = new URLSearchParams();
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (value !== null && value !== undefined && value !== '') {
          params.set(key, object[key]);
        }
      }
    }
    return params.toString();
  }

  public objectToQueryParams(data: object = {}): HttpParams {
    let params: HttpParams = new HttpParams();
    Object.keys(data).forEach(function (item) {
      params = params.set(item, data[item]);
    });
    return params;
  }

  /*
   * Takes an array and keyname and convert it into a query string array
   * in the format of 'surveyIds[]=123&surveyIds[]=125'
   */
  public arrayToQueryString(keyName: string, arr = []): string {
    if (!isArray(arr)) {
      throw new Error('Param is not an array');
    }

    const data = arr;
    let qs = '';
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      if (item !== null && item !== undefined && item !== '') {
        if (i !== 0) {
          qs += '&';
        }
        qs += `${keyName}[]=${item}`;
      }
    }

    return qs;
  }
}
