import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class MarcaPrd extends Model {
  public CodMar: string;
  public NomMar: string;
  public AbrMar: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): MarcaPrd {
    return new MarcaPrd(data);
  }

  public static casts(data: object[]): MarcaPrd[] {
    return map(data, c => new MarcaPrd(c));
  }
}
