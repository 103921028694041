import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FunctionsService } from 'src/app/core/helpers';
import { ClientService, ConfigurationService } from 'src/app/core/services';
import { Result } from '../../models';

@Component({
  selector: 'app-modal-doc-pending',
  templateUrl: './modal-doc-pending.component.html',
  styleUrls: ['./modal-doc-pending.component.scss']
})
export class ModalDocPendingComponent implements OnInit, OnDestroy {

  @Input('flagLinCre') flagLinCre: number;
  @Input('codUsu') var_codUsu: string;
  
  nroDecimal: number;
  pageActual: number = 1;

  public ClientQuery: any= {};
  public dataMsjError: any = {};
  private subscriptions: Subscription = new Subscription();
  @ViewChild('modalError') modalError: TemplateRef<any>;

  constructor(
    public configsvr: ConfigurationService,
    public clientService: ClientService,
    private modalService: NgbModal,
    public mf: FunctionsService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.nroDecimal = this.configsvr.result.nroDecimal_totals;    
  }

  ngOnInit(): void {
  }

  openModalcta(contentCTA) {    
    this.subscriptions.add(this.getClientByID(this.var_codUsu))
    this.modalService.open(contentCTA, { size: 'lg', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
  }

  private getClientByID(codUsu: string): Subscription {
    const data = this.mf.getDataFilterClient(codUsu);
    return this.clientService.getClientByFilterOLD(data).subscribe((res: Result) => {
      if (res.success) {
        this.ClientQuery = res;
      } else {
        this.setDataError(res, this.modalError);
      }
    })
  }

  private setDataError(datamsj: any, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    this.modalService.open(nameModal, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
  }

}
