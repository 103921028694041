import {isString, map, pickBy} from 'lodash';

import {Model} from './model';

export class Login extends Model {
  public id: number;
  public NomUsuario: string;
  public PwdUsuario: string;
  public Email: string;
  public Pwd_Email: string;

  constructor(data?: object) {
    super(data);
    this.NomUsuario = this.NomUsuario || '';
    this.PwdUsuario = this.PwdUsuario ||  '';
    this.Email =  this.Email || 'prueba@navasoft.com.pe';
    this.Pwd_Email = this.Pwd_Email || '1001';
  }

  public static cast(data: object): Login {
    return new Login(data);
  }

  public static casts(data: object[]): Login[] {
    return map(data, u => new Login(u));
  }
}
