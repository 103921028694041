import { Injectable } from '@angular/core';
import { ProductFamily, ProductLogs, ProductSubfamily, Result } from 'src/app/shared/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfigurationService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  loadingMenu: boolean;
  public menuItems = new BehaviorSubject<ProductLogs>(null);
  public loadMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMenuOutside: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDisplayMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // private loading: boolean;
  // private productLogs: ProductLogs;
  // private subfamilia: ProductSubfamily;
  // private familia: ProductFamily;
  // private subscriptions: Subscription = new Subscription();

  constructor(
    private config: ConfigurationService,
    private alertService: AlertService,
    private apiService: ApiService,
    private http: HttpClient
  ) { }

  public get baseUrl(): string {
    let apiUrl: string = (this.config.result.envName === undefined) ? this.config.result.apiUrl + `WA_eCommerce${this.config.result.codCia}/api/Producto/` : this.config.result.apiUrl + `api/Producto/`;
    return apiUrl;
  }

  public getPresentProductByFilter(product: any): Observable<Result> {
    const endpoint = `${this.baseUrl}GetPresenProducto`;
    return this.http.post(endpoint, product, this.apiService.requestOptions).pipe(map(Result.cast));
  }

  public getTblClasifPrd(): Observable<ProductLogs> {
    const endpoint = `${this.baseUrl}GetTblClasifPrd`;
    return this.http.get(endpoint, this.apiService.requestOptions).pipe(map(ProductLogs.cast));
  }


}
