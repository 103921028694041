import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class ClientAlias extends Model {
  public IdAlias: number;
  public CodCli: string;
  public Nombre: string;
  public Direccion: string;
  public Ubigeo: string;
  public RucCli: string;
  public TipoAlias: number;
  public IdLocal: string;
  public NomLocal: string;
  public EmailFe1: string;
  public EmailFe2: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ClientAlias {
    return new ClientAlias(data);
  }

  public static casts(data: object[]): ClientAlias[] {
    return map(data, c => new ClientAlias(c));
  }
}
