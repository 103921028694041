import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';
import {Pais} from './pais';
import {Departamento} from './departamento';
import {Provincia} from './provincia';
import {Distrito} from './distrito';
import {Local} from './local';
import {Tipodespacho} from './tipodespacho';
import {Ctabco} from './ctabco';


export class UbigeoCast extends Model {
  public message: string;
  public data: Ubigeo;
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): UbigeoCast {
    return new UbigeoCast(data);
  }

  public static casts(data: object[]): UbigeoCast[] {
    return map(data, c => new UbigeoCast(c));
  }
}

export class Ubigeo extends Model {
  public Id: string;
  public pais: Pais[];
  public departamento: Departamento[];
  public provincia: Provincia[];
  public distrito: Distrito[];
  public local: Local[];
  public tipodespacho: Tipodespacho;
  public ctabco: Ctabco[];

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Ubigeo {
    return new Ubigeo(data);
  }

  public static casts(data: object[]): Ubigeo[] {
    return map(data, c => new Ubigeo(c));
  }
}

