import { Component, OnInit, Input } from '@angular/core';
import * as L from "leaflet";

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {

  @Input('latitud') lat: number;
  @Input('longitud') lng: number;
  @Input('dataEmp') nroDoc: string;
  @Input('markerOP') opm: number;
  tilesProvider = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  marker: L.Marker;
  map: L.Map;

  constructor() { }

  ngOnInit(): void {
    this.loaderMap();
    this.loaderMarker();
  }

  loaderMap() {
    this.map = L.map('maps', {
      center: [this.lat, this.lng],
      zoomControl: true,
      doubleClickZoom: true,
      dragging: true,
      zoom: 16,
      zoomAnimation: true,
      scrollWheelZoom: true,
    })
    
    L.tileLayer(this.tilesProvider, {
      // attribution: '&copy; <a href="https://navasoft.com.pe/" target="_blank">Navasoft</a>',
      maxZoom: 17,
      minZoom: 13
    }).addTo(this.map);
  }

  loaderMarker() {
    this.marker = L.marker([this.lat, this.lng], {
      icon: this.geticonMarker(this.opm),
      draggable: false
    }).addTo(this.map).bindPopup(this.getPopup(this.nroDoc, this.opm)).openPopup();
  }

  private geticonMarker(opm: number) {
    switch (opm) {
      case 1:
        let marker01 = L.icon({
          iconUrl: 'assets/images/marker01.png',
          iconSize: [50, 50]         
        })
        return marker01;
        break;

      case 2:
        let marker02 = L.icon({
          iconUrl: 'assets/images/marker02.png',
          iconSize: [60, 36]
        })
        return marker02;
        break;

      default:
        break;
    }
  }

  private getPopup(data: string, opm: number) {
    let html = '';
    switch (opm) {
      case 1:
        html = '<center><b>'+ data +'</b></center>'
        return html;
        break;
      case 2:
        html = '<center>Tu Pedido<br> <b>Nro: </b>' + data + '</center>'
        return html;
        break;
      default:
        break;
    }    
  }

}
