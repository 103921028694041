import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class Departamento extends Model {
  public Id: number;
  public CodPai: string;
  public NomPai: string;
  public CodDep: string;
  public NomDep: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): Departamento {
    return new Departamento(data);
  }

  public static casts(data: object[]): Departamento[] {
    return map(data, c => new Departamento(c));
  }
}
