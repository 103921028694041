import { each, isNumber, isString, map, reduce } from 'lodash';
import { Model } from './model';

export class BookClaim extends Model {

	// public Id: number;
	public FecReg: Date;
	public CodUsu: string;
	public NomUsu: string;
	public ApeUsu: string;
	public Email: string;
	public TelUsu: string;
	public DirUsu: string;
	public TipoDoc: string;
	public NroDoc: string;
	public TipoReclamo: number;
	public TipoRelacionado: number;
	public FechaCompra: Date;
	public Cdocu: string;
	public Ndocu: string;
	public Detalle: string;
	public Solicita: string;

	constructor(data?: object) {
		super(data);
		this.FecReg = new Date();
		this.CodUsu = this.CodUsu || '';
		this.NomUsu = this.NomUsu || '';
		this.ApeUsu = this.ApeUsu || '';
		this.Email = this.Email || '';
		this.TelUsu = this.TelUsu || '';
		this.DirUsu = this.DirUsu || '';
		this.TipoDoc = this.TipoDoc || '';
		this.NroDoc = this.NroDoc || '';
		this.TipoReclamo = this.TipoReclamo || 0;
		this.TipoRelacionado = this.TipoRelacionado || 0;
		this.FechaCompra = this.FechaCompra || new Date();
		this.Cdocu = this.Cdocu || '';
		this.Ndocu = this.Ndocu || '';
		this.Detalle = this.Detalle || '';
		this.Solicita = this.Solicita || '';
	}

	public static cast(data: object): BookClaim {
		return new BookClaim(data);
	}

	public static casts(data: object[]): BookClaim[] {
		return map(data, c => new BookClaim(c));
	}
}