import { Component, Input, OnInit } from '@angular/core';
import { FunctionsService } from 'src/app/core/helpers';
import { ToolsService } from 'src/app/core/services';

@Component({
  selector: 'app-banner-result',
  templateUrl: './banner-result.component.html',
  styleUrls: ['./banner-result.component.scss']
})
export class BannerResultComponent implements OnInit {

  @Input('flagView') flagView: boolean;
  @Input('flagResult') flagResult: boolean;
  @Input('resultTexto') resultTexto: string;
  @Input('tipDoc') tipDoc: number;
  @Input('nrodocumento') nrodocumento: string;
  loading:boolean = true;
  constructor(
    public mf: FunctionsService,
    public toolsService: ToolsService,
  ) { }

  ngOnInit(): void {
  }

  downloadOrder() {
    this.loading = false;
    const nroPed = this.nrodocumento;
    const tipdocu = (this.tipDoc == 1) ? '32':'31';
    const data = this.mf.loaderDatabyPDF(tipdocu, nroPed, null, 1, 0);
    return this.toolsService.getOrdersByPDF(data).subscribe((res: any) => {
      if (res.success) {
        this.mf.convertBase64toPDF(res.data);
        this.loading = true;
      }
    })
  }

}
