import {isString, map, pickBy} from 'lodash';

import {Model} from './model';
import {TokenData} from './token';

export class UserCast extends Model {
  public message: string;
  public data: User;
  public error: string;
  public success: boolean;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): User {
    return new User(data);
  }

  public static casts(data: object[]): User[] {
    return map(data, u => new User(u));
  }
}

export class User extends Model {
  public idUsu: number;
  public codUsu: string;
  public nomUsu: string;
  public apeUsu: string;
  public email: string;
  public pwdUsu: string;
  public telUsu: string;
  public ubigeo: string;
  public dirEnt: string;
  public obse: string;
  public tipoUsu: number;
  public codCdv: string;
  public tipoDocSnt: string;
  public nroDniUser: string;
  public nroDocSnt: string;
  public nomCli: string;
  public dirCli: string;
  public regimenSnt: number;
  public moneda: string;
  public tasaIgv: number;
  public tcambio: number;
  public precioIncluyeIgv: number;
  public pwdUsuOLD: string;

  // add Column
  public tipodespacho: string;
  public local: string;
  public payment: string;
  public codPai: string;
  public codDep: string;
  public codPro: string;
  public codDis: string;
  public nomUbigeo: string;
  // End
  public token: TokenData

  constructor(data?: object) {
    super(data);
    this.nroDniUser = this.nroDniUser || 'DNI00000000';
    this.codPai = this.codPai || isString(this.ubigeo) ? this.ubigeo.substring(0, 2) : '';
    this.codDep = this.codDep || isString(this.ubigeo) ? this.ubigeo.substring(2, 4) : '';
    this.codPro = this.codPro || isString(this.ubigeo) ? this.ubigeo.substring(4, 6) : '';
    this.codDis = this.codDis || isString(this.ubigeo) ? this.ubigeo.substring(6, 8) : '';
  }

  public static cast(data: object): User {
    return new User(data);
  }

  public static casts(data: object[]): User[] {
    return map(data, u => new User(u));
  }
}
