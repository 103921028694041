import { Injectable } from '@angular/core';

export interface ITokenService {
  get(key: string): string;
  getObject(key: string): object;
  getAll(): object;
}

@Injectable({
  providedIn: 'root'
})
export declare class TokenService implements ITokenService {

  // constructor() { }

  /**
   * @name TokenService#get
   *
   * @description
   * Returns the value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Raw cookie value.
   */
  get(key: string): string;
  /**
   * @name TokenService#getObject
   *
   * @description
   * Returns the deserialized value of given cookie key.
   *
   * @param key Id to use for lookup.
   * @returns Deserialized cookie value.
   */
  getObject(key: string): object;
  /**
   * @name TokenService#getAll
   *
   * @description
   * Returns a key value object with all the cookies.
   *
   * @returns All cookies
   */
  getAll(): object;

}
