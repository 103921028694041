import { Injectable } from '@angular/core';
import {ConfigurationService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(
    private config: ConfigurationService
  ) { }

  public get baseUrl(): string {
    return this.config.result.imageUrl;
  }
}
