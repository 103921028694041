import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConfigurationService, SearchService } from 'src/app/core/services';
import { FunctionsService } from 'src/app/core/helpers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Result } from '../../models';

@Component({
  selector: 'app-modal-stoc-warehouse',
  templateUrl: './modal-stoc-warehouse.component.html',
  styleUrls: ['./modal-stoc-warehouse.component.scss']
})
export class ModalStocWarehouseComponent implements OnInit {

  loadingspinner: boolean;
  listWarehouses: any = [];
  dataMsjError: any = {};
  viewTableResult: boolean;
  stockTotalGen: number = 0;
  umedPrd: string;
  flagViewCtrl: boolean;
  viewTypeResultSearch: number;
  @Input('idcodi') idcodi: string;
  @Input('nameProduct') nameProduct: string;
  @ViewChild('modalError') modalError: TemplateRef<any>;
  @ViewChild('contentSelectPrd') modalSelectPrd: TemplateRef<any>;

  constructor(
    public configsvr: ConfigurationService,
    public searchPrd: SearchService,
    private modalService: NgbModal,
    public mf: FunctionsService,
  ) { }

  ngOnInit(): void {
    this.viewTypeResultSearch = this.configsvr.result.viewTypeResultSearch;
    this.flagViewCtrl = this.mf.getValueFlagCtrlByTypeCompany(this.configsvr.result.typeCompany);
  }

  openModalSelectProduct() {
    this.loadingspinner = true;
    this.searchPrd.getStockOtherWarehouse(this.idcodi).subscribe((res: Result) => {
      if (res.success) {
        if (res.data.length > 0) {
          setTimeout(() => {
            this.loadingspinner = false;
          }, 1000);
          this.listWarehouses = res.data
          this.umedPrd = this.listWarehouses[0].umed;
          this.stockTotalGen = this.calTotalStock(res.data);
          this.viewTableResult = true;
          this.modalService.open(this.modalSelectPrd, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
        } else {
          this.stockTotalGen = 0;
          this.viewTableResult = false;
          this.loadingspinner = false;
          this.modalService.open(this.modalSelectPrd, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' });
        }
      } else {
        this.setDataError(res, this.modalError);
      }
    }, err => {
      this.loadingspinner = false;
    })
  }

  private setDataError(datamsj: Result, nameModal: any) {
    this.dataMsjError = {
      nameErr: datamsj.message,
      detailErr: datamsj.error
    }
    this.modalService.open(nameModal, { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' }).result.then((result) => {
      this.loadingspinner = false;
    }, (reason) => {
      this.loadingspinner = false;
    });
  }

  private calTotalStock(listaAlms: any): number {
    let total: number = 0;
    for (let i = 0; i < listaAlms.length; i++) {
      total = total + listaAlms[i].stockDispon;
    }
    return this.mf.roundDecimals(total, 2);
  }

}
