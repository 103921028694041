import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageErrorComponent } from 'src/app/shared/components/modal-message-error/modal-message-error.component';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor(
    private modalService: NgbModal
  ) { }

  public sortObject(column: string, obj, sort: string = 'asc'): any {
    if (!_.isUndefined(obj) && _.includes(['desc', 'asc'], sort)) {
      const sortDirection: 'asc' | 'desc' = sort as ('asc' | 'desc');
      // Lodash and Typescript type mappings are clashing, need to cast as <any>
      // its proper cast <productQuery[]> does not work.
      /* tslint:disable-next-line */
      return <any>_.orderBy(obj, [column], [sortDirection]);
    }
    return [];
  }

  /*******************************************************************/
  /*******EXTRAE DIRECTAMENTE  FECHA ACTUAL - RETORNA STRING*******************************************/
  public GetDateNow() {
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + (month) + "-" + (day);
    return today;
  }
  /********EXTRAE FECHA ADELANTADA DEPENDIENDO DE LOS DIAS INGRESADOS - RETORNA DATE******************************************/
  public getDateadvanced(nrodias: number) {
    let now = new Date();
    let dayPas = new Date(now.getTime() + (1000 * 60 * 60 * 24 * nrodias));
    return dayPas;
  }
  /*******EXTRAE FECHA ATRAZADA DEPENDIENDO DE LOS DIAS INGRESADOS - RETORNA STRING*******************************************/
  public getDatePassed(nrodias: number) {
    let now = new Date();
    let dayPas = new Date(now.getTime() - (1000 * 60 * 60 * 24 * nrodias));
    let newdayPas = this.convertDateSTRINGcombo(dayPas);
    return newdayPas;
  }
  public convertDateSTRINGcombo(fecha: Date) {
    var day = ("0" + fecha.getDate()).slice(-2);
    var month = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var today = fecha.getFullYear() + "-" + (month) + "-" + (day);
    return today;
  }
  /******* PARCEA CEROS A LA IZQUIERDA*******************************************/
  public padCEROS(number, width) {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
      if (number < 0) {
        return ("-" + numberOutput.toString());
      } else {
        return numberOutput.toString();
      }
    } else {
      if (number < 0) {
        return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
      } else {
        return ((zero.repeat(width - length)) + numberOutput.toString());
      }
    }
  }
  /*******REDONDEAR DECIMALES DEPENDIENDO DE LA CANTIDAD*******************************************/
  public roundDecimals(numero: any, decimales: any) {
    return Number(Number.parseFloat(numero).toFixed(decimales));
  }
  /*******CONVIERTE CADENA BASE64 A PDF*******************************************/
  public convertBase64toPDF(data: string) {
    try {
      let byteCaracteres = atob(data);
      var byteNumeros = new Array(byteCaracteres.length);
      for (var i = 0; i < byteCaracteres.length; i++) {
        byteNumeros[i] = byteCaracteres.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumeros);
      var archivo = new Blob([byteArray], { type: 'application/pdf;base64' });
      var archivoURL = URL.createObjectURL(archivo);
      window.open(archivoURL);
    } catch (error) {
      console.log(error);
    }
  }
  /***********A UN NUMERO AGREGA (.00)***************************************/
  public getConvertDecimal(nroDecimal: number): String {
    let stringNumber: string = "";
    switch (this.giveMeDecimals(nroDecimal)) {
      case 1:
        stringNumber = ".00";
        break;
      case 10:
        stringNumber = "0";
        break;
      case 100:
        stringNumber = "";
        break;
    }
    const newNro = nroDecimal + "" + stringNumber;
    return newNro;
  }

  private giveMeDecimals(numero: Number): Number {
    let numeroString: string = String(numero);
    let numeroArray: Array<string> = numeroString.split(".");
    let decimals: number = (numeroArray.length > 1) ? numeroArray[1].length : 0;
    const newnro = Math.pow(10, decimals)
    return newnro;
  }
  //****************************************************************************//
  /*******DEVUELVE EXISTENCIA DE PRODUCTO EN EL CARRITO**************************/
  public getStatusPrdCar(list: FormArray, nameProduct: string): boolean {
    let flagExistShop = false;
    Object.values(list.controls).forEach(SubControl => {
      if (SubControl.value.ProducSelect === nameProduct) {
        flagExistShop = true
      }
    });
    return flagExistShop;
  }
  /*******DEVUELVE EL TOTA (BASE IMPONIBLE)*******************************************/
  public getTotalBaseImpo(totn: number, aigv: string, regimen: number, nrodecimal: number, tasaigv: number): number {
    let tota = 0;
    let tasa = 1 + (tasaigv / 100);
    if (regimen <= 1) {
      if (aigv === 'S') {
        tota = totn / tasa;
      } else {
        tota = totn;
      }
    } else {
      tota = totn;
    }
    return this.roundDecimals(tota, nrodecimal);
  }
  /*******DEVUELVE EL TOTN *******************************************/
  public getTotnRegister(tota: number, aigv: string, regimen: number, nrodecimal: number, tasaigv: number): number {
    let totn = 0;
    let tasa = 1 + (tasaigv / 100);
    if (regimen <= 1) {
      if (aigv === 'S') {
        totn = tota * tasa;
      } else {
        totn = tota;
      }
    } else {
      totn = tota;
    }
    return this.roundDecimals(totn, nrodecimal);
  }
  /**************************************** */
  public getImporteCarShop(priceInclIGV: number, tota: number, totn: number) {
    let importe = 0;
    if (priceInclIGV === 1) {
      importe = totn;
    } else {
      importe = tota;
    }
    return importe
  }
  /*******CALCULA EL TOTAL GENERAL DEL CARRITO*************************************************/
  public getTotnGen(list: FormArray, nrodecimal: number): number {
    let tmpTotal: number = 0;
    Object.values(list.controls).forEach(mycontrol => {
      tmpTotal = this.roundDecimals((tmpTotal + Number(mycontrol.value.totn)), nrodecimal);
    })
    return tmpTotal
  }
  /********CALCULA LA BASE IMPONIBLE DEL CARRITO******************* */
  public getTotaGen(list: FormArray, nrodecimal: number): number {
    let tmpTotal: number = 0;
    Object.values(list.controls).forEach(mycontrol => {
      tmpTotal = this.roundDecimals((tmpTotal + Number(mycontrol.value.tota)), nrodecimal);
    })
    return tmpTotal
  }
  /*************************** */
  public getTotalCarShop(list: Array<any>, nrodecimal: number, priceIncluIgv: number): number {
    let tmpTotal: number = 0;
    list.forEach(function (item) {
      if (priceIncluIgv === 1) {
        tmpTotal = tmpTotal + Number(item.totn);
      } else {
        tmpTotal = tmpTotal + Number(item.tota);
      }
    })
    return this.roundDecimals(tmpTotal, nrodecimal);
  }
  /*******DEVUELVE EL TOTAL DE LA COMPRA POR PRODUCTO*******************************************/
  public gettotalFilaCar(cant: number, price: number, nrodecimal: number): number {
    return this.roundDecimals((cant * price), nrodecimal);
  }
  /*******EXTRAE PRECIO SEGUN MONEDA*******************************************/
  public getpriceByMone(mone: string, pvns: number, pvus: number, nrodecimal: number, priceIncluIgv: number, vvns: number, vvus: number) {
    let price = 0;
    if (priceIncluIgv === 1) {
      price = ((mone === 'S') ? pvns : pvus)
    } else {
      price = ((mone === 'S') ? vvns : vvus)
    }
    // return this.roundDecimals(((mone === 'S') ? val1 : val2), nrodecimal);
    return this.roundDecimals(price, nrodecimal);
  }
  /*******EXTRAE DESCUENTO*******************************************/
  public getDescuento(price: number, desct: number, nrodecimal: number) {
    return this.roundDecimals(((price * desct) / 100), nrodecimal);
  }
  /*******EXTRAE PRECIO CON DESCUENTO*******************************************/
  public getPriceBydesct(price: number, desct: number, nrodecimal: number) {
    return this.roundDecimals((price - ((price * desct) / 100)), nrodecimal);
  }
  /*******EXTRAE PRECIO SEGÚN MONEDA*******************************************/
  public getsimbolMone(mone: string) {
    return ((mone === 'S') ? 'S/' : 'US$');
  }
  /*******EXTRAE PRECIO SEGÚN MONEDA*******************************************/
  public getConvertPriceByMone(mone_prd: string, mone_default: string, pvns: number, pvus: number, tipcam: number, priceIncluIgv: number, vvns: number, vvus: number) {
    let price = 0;
    if (mone_default === mone_prd) {
      if (priceIncluIgv === 1) {
        price = (mone_prd === 'S') ? pvns : pvus;
      } else {
        price = (mone_prd === 'S') ? vvns : vvus;
      }
    } else {
      if (mone_default === 'S' && mone_prd === 'D') {
        if (priceIncluIgv === 1) {
          price = pvus * tipcam;
        } else {
          price = vvus * tipcam;
        }
      }
      if (mone_default === 'D' && mone_prd === 'S') {
        if (priceIncluIgv === 1) {
          price = pvns / tipcam;
        } else {
          price = vvns / tipcam;
        }
      }
    }
    return price;
  }
  /*******DEVUELVE ESTADO DE PRODUCTO*******************************************/
  public getStatusPrdByStoc(stock: number) {
    return (stock > 0) ? 'DISPONIBLE' : 'AGOTADO';
  }
  /*********************************************************/
  public getTipyByCodeDeli(number: number): String {
    switch (number) {
      case 1:
        return 'CLIENTE RECOGE INMEDIATO';
        break;

      case 2:
        return 'CLIENTE ESPERA';
        break;

      case 3:
        return 'ENVIO AL LOCAL DEL CLIENTE';
        break;

      case 4:
        return 'ENVIO A PROVINCIA VIA TRANS';
        break;

      default:
        return 'NO TIENE VALOR!!!';
        break;
    }
  }
  /*********************************************************/
  public getColorStatusDoc(valor: string): String {
    switch (valor) {
      case 'Atendido':
        return 'green';
        break;

      case 'Evaluacion':
        return 'red';
        break;

      case 'Aprobado':
        return '#adad0c';
        break;

      default:
        return 'red';
        break;
    }
  }
  /*********VALIDA ESTADO DEL DOCUMENTO******************************************/
  public getStatusDOC(flag: string, flagApro: string): number {
    let dataresult = 0;
    if (flag === '*' && flagApro === '0') {
      dataresult = 1; // ANULADO (P-C)
    }
    if (flag === '1' && flagApro === '1') {
      dataresult = 2; // ATENDIDO (P)
    }
    if (flag === '1' && flagApro === '3') {
      dataresult = 2; // ATENDIDO (C)
    }
    if (flag === '0' && flagApro === '1') {
      dataresult = 3; // APROBADO (P)
    }
    if (flag === '0' && flagApro === '2') {
      dataresult = 3; // APROBADO (C)
    }
    if (flag === '0' && flagApro === '0') {
      dataresult = 4; // EVALUACION (P-C)
    }
    return dataresult;
  }
  /************MODIFICACION DE CADENA %***********************/
  public replaceString(cadena: string) {
    let newtexto = '';
    if (cadena !== undefined && cadena !== '' && cadena !== null) {
      const regex = / /gi;
      newtexto = '%' + cadena.replace(regex, '%');
    }
    return newtexto;
  }

  /************MODIFICACION DE CADENA ***********************/
  public renderDataCellResumenCant(prd: any, var_docu: string) {
    let textCell: any = '';
    if (prd.medidacorte === 0) {
      textCell = prd.cantSelect;
    } else {
      if (var_docu === 'new') {
        textCell = this.roundDecimals((prd.cantSelect / prd.undConvPres), 1);
      } else {
        textCell = prd.cantSelect;
      }
    }
    return textCell;
  }

  public renderDataCellResumenUmed(prd: any, var_docu: string) {
    let textCell: any;
    if (prd.medidacorte === 0) {
      textCell = prd.UmedSelect;
    } else {
      if (var_docu === 'new') {
        textCell = prd.umed;
      } else {
        textCell = prd.UmedSelect;
      }
    }
    return textCell;
  }
  /********CALCULO DE COSTO DE UN PRODUCTO - DETALLE DEL DOCUMENTO**************************/
  public getValCostoProduct(moneDoc: string, moneProduct: string, pans: number, paus: number, tipcamp: number, nrodecimal: number) {
    let cost: number = 0;

    if (moneProduct === 'S' && moneDoc === 'S') {
      cost = pans;
    }

    if (moneProduct === 'S' && moneDoc === 'D') {
      cost = pans / tipcamp;
    }

    if (moneProduct === 'D' && moneDoc === 'D') {
      cost = paus;
    }

    if (moneProduct === 'D' && moneDoc === 'S') {
      cost = paus * tipcamp;
    }

    return this.roundDecimals(cost, nrodecimal);
  }

  /************FILTROS**************************************/
  public getDataFilterPresen(codProduct: string, codecli: string, codeCdv: string) {
    const data = {
      idcodi: codProduct,
      codigo: "",
      producto: "",
      marca: "",
      codmarca: "",
      codalm: "",
      codcli: codecli,
      codlocal: "001",
      codcdv: codeCdv,
      codusu: "050",
      tipoquery: 5
    }
    return data;
  }
  /***************************************************************/
  public getDataFilterClient(codusu: string) {
    const data = {
      idcodigo: codusu,
      nombre: "",
      ruc: "",
      codven: "",
      tipoquery: 4
    }
    return data;
  }
  /***************************************************************/
  public getdataProductobyFilter(valText: any, codecli: string, codcdv: string, codMark: string, codFam: string = '') {
    const data = {
      idcodi: codFam || '',
      codigo: ((valText.codigo) ? valText.codigo : '') || '',
      producto: ((valText.producto) ? valText.producto : '') || '',
      marca: ((valText.marca) ? valText.marca : '') || '',
      codmarca: codMark || '',
      codalm: "",
      codcli: codecli,
      codlocal: "001",
      codcdv: codcdv,
      codusu: "050",
      tipoquery: 6
    }
    return data;
  }
  /***************************************************************/
  public getdataProductobyFilterByID(idcodi: string, codecli: string, codcdv: string) {
    const data = {
      idcodi: idcodi,
      codigo: "",
      producto: "",
      marca: "",
      codmarca: "",
      codalm: "01",
      codcli: codecli,
      codlocal: "001",
      codcdv: codcdv,
      codusu: "050",
      tipoquery: 6
    }
    return data;
  }
  /***************************************************************/
  public getDataDeleteOrder(valcdocu: string, valndocu: string) {
    const data = {
      cdocu: valcdocu,
      ndocu: valndocu,
      idcodigo: ""
    }
    return data;
  }
  /***************************************************************/
  public loaderDataFilter(cdocu: string, codcli: string, fechIni: string, fechFin: string, idUsu: string) {
    const data = {
      cdocu: cdocu,
      ndocu: "",
      idcodigo: codcli,
      nombre: "",
      ruc: "",
      fechaDesde: fechIni,
      fechaHasta: fechFin,
      codusu: idUsu
    }
    return data;
  }
  /***************************************************************/
  public loaderDatabyPDF(cdocu?: string, nroPed?: string, codcli?: string, typeReport?: number, idReport?: number) {
    const data = {
      Cdocu: cdocu || "",
      Ndocu: nroPed || "",
      PageSize: "A4",				//Obligatorio
      TypeReport: typeReport || "",				//	Obligatorio - 2
      IdReport: idReport || 0,					//Obligatorio - 4
      CodLocal: "",
      PageOrientation: 0,				//Obligatorio
      AnchoPixeles: 1200,
      fecha: this.GetDateNow(),
      FiltroAdd: codcli || "C00000"				//Obligatorio
    }
    return data;
  }
  /***************************************************************/
  public getDataSendMail(cdocu: string, ndocu: string, emailaddres: string) {
    const data = {
      Cdocu: cdocu,
      Ndocu: ndocu,
      BodyHtml: "",
      TypeReport: 1,
      IdReport: 0,
      AttachDocument: 1,
      MailIncludeInfo: 0,
      PageSize: "A4",
      PageOrientation: 0,
      AnchoPixeles: 1200,
      SmtpFrom: "ventas",
      FromAddress: "eCommerce@navasoft.com.pe",
      EmailAddress: emailaddres,
      Subject: "Adjuntamos a la presente el siguiente Documento:",
      Content: "",
      Firma: "eCommerce"
    }
    return data;
  }
  /***************************************************************/
  public getDataMarcaPrdByFilter(codcli: string, codusu: string) {
    const data = {
      codmarca: "",
      codcli: codcli,
      codusu: codusu,
      tipoquery: 6
    }
    return data;
  }
  /***************************************************************/
  public getValueFlagCtrlByTypeCompany(typeCompany: string) {
    let valueCtrl: boolean;
    switch (typeCompany) {
      case 'standar':
        valueCtrl = false;
        break;

      case 'games':
        valueCtrl = true;
        break;

      default:
        valueCtrl = false;
        break;
    }
    return valueCtrl;
  }
  /***************************************************************/
  public setDataError(error: any, typeMsg: number): NgbModalRef {
    let dataMsjError: any
    switch (typeMsg) {
      case 1:
        dataMsjError = { nameErr: `${error.name}-${error.statusText}`, detailErr: `${error.message}`, codeErr: error.status };
        break;

      case 2:
        dataMsjError = { nameErr: error.message, detailErr: error.error, codeErr: 200 };
        break;

      default:
        break;
    }

    let config: any = { size: 'md', centered: true, backdropClass: 'light-blue-backdrop', windowClass: 'modal-holder' };
    const modalRef: NgbModalRef = this.modalService.open(ModalMessageErrorComponent, config);
    modalRef.componentInstance.dataMsjError = dataMsjError;
    return modalRef
  }



}
