import { each, isNumber, isString, map, reduce } from 'lodash';
import { Model } from './model';
import { DtlCotizacion } from './dtl-cotizacion';
import { ClientAuditoria } from './client';

export class Cotizacion extends Model {

	public Fecha: Date;
	public Cdocu: string;
	public Ndocu: string;
	public CodCli: string;
	public NomCli: string;
	public RucCli: string;
	public Atte: string;
	public Nrefe: string;
	public Requ: string;
	public Mone: string;
	public Tcam: number;
	public Tota: number;
	public Toti: number;
	public Totn: number;
	public Flag: string;
	public CodVen: string;
	public CodCdv: string;
	public Fven: Date;
	public Dura: number;
	public CodPto: string;
	public Operacion: string;
	public Obser: string;
	public Estado: string;
	public Obsere: string;
	public DirEnt: string;
	public CodScc: string;
	public IdAlias: number;
	public Frontera: number;
	public CodPos: string;
	public CodFdp: string;
	public CodTar: string;
	public Origen: number;
	public TipoEntrega: number;
	public Cdge: string;
	public Ndge: string;
	public IcbPer: number;
	public FecApr: Date;
	public Detalle: DtlCotizacion[];
	public Auditoria: ClientAuditoria;

	//Campos adicionales TMP solo para edicion y consulta
	public NomEstado: string;
	public Email: string;
	public NomVen: string;
	public NomCdv: string;


	constructor(data?: object) {
		super(data);
		const date = new Date();
		this.Fecha = this.Fecha || new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
		this.Cdocu = this.Cdocu || '31';
		this.Ndocu = this.Ndocu || '000-00000000';
		this.CodCli = this.CodCli || '';
		this.NomCli = this.NomCli || '';
		this.RucCli = this.RucCli || '';
		this.Atte = this.Atte || '';
		this.Nrefe = this.Nrefe || '';
		this.Requ = this.Requ || '';
		this.Mone = this.Mone || 'S';
		this.Tcam = this.Tcam || 3.000;
		this.Tota = this.Tota || 0;
		this.Toti = this.Toti || 0;
		this.Totn = this.Totn || 0;
		this.Flag = this.Flag || '0';
		this.CodVen = this.CodVen || 'V0000';
		this.CodCdv = this.CodCdv || '01';
		this.Fven = this.Fven || new Date();
		this.Dura = this.Dura || 10;
		this.CodPto = this.CodPto || '01';
		this.Operacion = this.Operacion || 'N';
		this.Obser = this.Obser || '';
		this.Estado = this.Estado || '0';
		this.Obsere = this.Obsere || '';
		this.DirEnt = this.DirEnt || '';
		this.CodScc = this.CodScc || '';
		this.IdAlias = this.IdAlias || 0;
		this.Frontera = this.Frontera || 0;
		this.CodPos = this.CodPos || '';
		this.CodFdp = this.CodFdp || '';
		this.CodTar = this.CodTar || '';
		this.Origen = this.Origen || 5;
		this.TipoEntrega = this.TipoEntrega || 0;
		this.Cdge = this.Cdge || '';
		this.Ndge = this.Ndge || '';
		this.IcbPer = this.IcbPer || 0;
		this.FecApr = this.FecApr || new Date();
		this.Auditoria =  this.Auditoria || new ClientAuditoria();
	}

	public static cast(data: object): Cotizacion {
		return new Cotizacion(data);
	}

	public static casts(data: object[]): Cotizacion[] {
		return map(data, c => new Cotizacion(c));
	}

}