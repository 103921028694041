import { NgModule } from '@angular/core';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SharedComponentsModule } from './components/shared-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';
import { RecaptchaModule } from 'ng-recaptcha';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export const MaterialModules = [];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    NgbModule,
    RecaptchaModule,
    ...MaterialModules,
    NgSelectModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    NgbModule,
    RecaptchaModule,
    ...MaterialModules,
    NgSelectModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
