import {each, isNumber, isString, map, reduce} from 'lodash';
import {Model} from './model';

export class ListDocuments extends Model {
  public Cdocu: string;
  public Ndocu: string;
  public Crefe: string;
  public Nrefe: string;
  public Fecha: Date;
  public FecVen: Date;
  public Dias: number;
  public Moneda: string;
  public Tcam: number;
  public CodCdv: string;
  public NomCdv: string;
  public Monto: number;
  public Saldo: number;
  public FlagDocVen: number;
  public FlagSemaforo: number;
  public CodEstLet: string;
  public NomEstLet: string;
  public NroUnicoLet: string;
  public CodBcoLet: string;
  public NomBcoLet: string;

  constructor(data?: object) {
    super(data);
  }

  public static cast(data: object): ListDocuments {
    return new ListDocuments(data);
  }

  public static casts(data: object[]): ListDocuments[] {
    return map(data, c => new ListDocuments(c));
  }
}
