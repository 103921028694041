import { Model } from './model';
import { isString, map, pickBy } from 'lodash';

export class ConfigFile extends Model {

    public envName: string;
    public codCia: string;
    public apiUrl: string;
    public imageUrl: string;

    /***==> DATA DE LA EMPRESA***************** */
    public namebusiness: string;
    public fromAddress: string
    public urlGoogleMaps: string;
    public linkSocialMedia: socialMedia[];
    public sectionFotter: sectionFotter;

    /***==> DATA DE CONFIGURACIÓN SISTEMA***************** */
    public typeCompany: string;
    public keyRecaptcha: string;
    public viewImageGrid: boolean;
    public viewImageFooterFE: boolean;
    public urlWebFE: string;
    public viewTypeSearchProduct: number;
    public selectTypeSearchCombo: string;
    public nroDecimal_price: number;
    public nroDecimal_totals: number;
    public flagViewStockOtherAlms: boolean;
    public flagViewDiscountAmountTable: boolean;
    public viewTypeResultSearch: number;

    /***==> DATA DE CONFIGURACIÓN TEMA DEL SISTEMA***************** */
    public theme: themeWeb[];

    constructor(data?: object) {
        super(data);
    }

    public static cast(data: object): ConfigFile {
        return new ConfigFile(data);
    }

    public static casts(data: object[]): ConfigFile[] {
        return map(data, u => new ConfigFile(u));
    }
}

export class themeWeb extends Model {
    public id: string;
    public valor: string;

    constructor(data?: object) {
        super(data);
        this.id = this.id || '';
        this.valor = this.valor || '';
    }

    public static cast(data: object): themeWeb {
        return new themeWeb(data);
    }

    public static casts(data: object[]): themeWeb[] {
        return map(data, u => new themeWeb(u));
    }
}

export class socialMedia extends Model {
    public nameRed: string;
    public icon: string;
    public url: string;

    constructor(data?: object) {
        super(data);
        this.nameRed = this.nameRed || '';
        this.icon = this.icon || '';
        this.url = this.url || '';
    }

    public static cast(data: object): socialMedia {
        return new socialMedia(data);
    }

    public static casts(data: object[]): socialMedia[] {
        return map(data, u => new socialMedia(u));
    }
}

export class sectionFotter extends Model {
    public howtoBuy: any[];
    public myCompany: any[];
    public contactUs: any[];

    constructor(data?: object) {
        super(data);
        this.howtoBuy = this.howtoBuy || [''];
        this.myCompany = this.myCompany || [''];
        this.contactUs = this.contactUs || [''];
    }

    public static cast(data: object): sectionFotter {
        return new sectionFotter(data);
    }

    public static casts(data: object[]): sectionFotter[] {
        return map(data, u => new sectionFotter(u));
    }
}